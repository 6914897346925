import { useTranslation } from 'react-i18next'
import './index.less'

const Info = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className='app-title'>MEETINGPAD</div>
      <div className='info'>
        {/* <div>{t('copyright_complaint')}</div>
        <div>|</div>
        <div>{t('service_agreement')}</div>
        <div>|</div> */}
        <div>{t('company')}</div>
        <div>|</div>
        <div>
          <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank' rel='noreferrer' style={{ color: 'white' }}>沪ICP备09095147号-90</a>
        </div>
        <div>|</div>
        {/* <div>{t('record_number')}35020602005</div>
        <div>|</div> */}
        <div>1.5.0-beta2-20240329</div>
      </div >
    </>
  )
}

export default Info
