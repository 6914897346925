import pdf from '../media/imgs/pdf.svg'
import image from '../media/imgs/image.svg'
import video from '../media/imgs/video.svg'

export const byteSize = { KB: 1024, MB: 1024 * 1024 }

export const timeSize = { second: 1, minute: 60, hour: 60 * 60, day: 24 * 60 * 60 }

export const mobileRegExp = /^1[3456789]\d{9}$/

export const codeRegExp = /^\d{6}$/

export const fileRegExp = /\.(pdf|jpg|jpeg|png|bmp|mp4|mpg)$/i

// export const errMessage = [
//   '文件违反了上传规则，请重新上传', // 0
//   '请阅读且同意用户协议与隐私政策', // 1
//   '验证码发送失败', // 2
//   '上传失败，请稍后重新上传', // 3
//   '复制成功', // 4
//   '复制失败', // 5
//   '文件内容与格式不符（列表中红色文件名），请确认文件格式', // 6
// ]

// .pdf, .jpg, .jpeg, .png, .bmp, .mp4, .mov
export const fileExtensions = ['.pdf', '.jpg', '.jpeg', '.png', '.bmp', '.mp4', '.mov']

export const mimeTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/bmp', 'video/mp4', 'video/quicktime']

export const fileIconMap = {
  'application/pdf': pdf,
  'image/jpeg': image,
  'image/png': image,
  'image/bmp': image,
  'video/mp4': video,
  // 'video/mpeg': video,
  'video/quicktime': video, // .mov
  // 'video/avi': video, // .avi
}

export const fileHeaderBinary = {
  'application/pdf': [0x25, 0x50, 0x44, 0x46, 0x2D],
  'image/jpeg': [0xFF, 0xD8, 0xFF],
  'image/png': [0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A],
  'image/bmp': [0x42, 0x4D],
  // 'video/mp4': [0x66, 0x74, 0x79, 0x70, 0x69, 0x73, 0x6F, 0x6D], // offset 4 12
  'video/mp4': [0x66, 0x74, 0x79, 0x70], // offset 4 8
  'video/mpeg': [0x00, 0x00, 0x01, 0xB3]
}

export const startWithNumber = /^\d/

export const startWithLetter = /^[a-z]/i

export const startWithZhNumber = /^[一二三四五六七八九十]/

export const zhNumberWeight = {
  '一': { weight: 1 },
  '二': { weight: 2 },
  '三': { weight: 3 },
  '四': { weight: 4 },
  '五': { weight: 5 },
  '六': { weight: 6 },
  '七': { weight: 7 },
  '八': { weight: 8 },
  '九': { weight: 9 },
  '十': { weight: 10 },
  '百': { weight: 11 },
  '千': { weight: 12 },
  '万': { weight: 13 },
  '亿': { weight: 14 },
  undefined: { weight: 0 }
}

// export const transferStatusText = {
//   transfer: '传输中',
//   finish: '已完成',
//   error: '传输失败'
// }

export const transferStatusText = {
  transfer: 'transfer_uploading',
  finish: 'transfer_finished',
  error: 'transfer_error',
}

// 区号
export const areaCode = [
  { area: 'china_mainland', code: '+86', label: '86_china_mainland' },
  { area: 'united_states', code: '+1', label: '1_united_states' },
  { area: 'canada', code: '+1', label: '1_canada' },
  { area: 'hong_kong_china', code: '+852', label: '852_hong_kong_china' },
  { area: 'taiwan_china', code: '+886', label: '886_taiwan_china' },
  { area: 'japan', code: '+81', label: '81_japan' },
  { area: 'singapore', code: '+65', label: '65_singapore' },
  { area: 'united_kingdom', code: '+44', label: '44_united_kingdom' },
  { area: 'germany', code: '+49', label: '49_germany' },
  { area: 'australia', code: '+61', label: '61_australia' },
  { area: 'france', code: '+33', label: '33_france' },
  { area: 'korea', code: '+82', label: '82_korea' },
  { area: 'india', code: '+91', label: '91_india' },
  { area: 'sweden', code: '+46', label: '46_sweden' },
]
