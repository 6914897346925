import { useState, useEffect } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import axios from 'axios'
import * as dd from 'dingtalk-jsapi'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import AppContext from './AppContext'
import Login from './pages/login'
import File from './pages/file'
import Agreement from './pages/agreement'
import Policy from './pages/policy'
import Info from './components/info'
import { isMobile, transformBlobToUrl, setAppBackground, openNotification } from './share/utils'
import transportLayer from './share/transportLayer'
import backgroundDefaultImage from './media/imgs/background-img.jfif'
import './App.less'


function App() {
  const { i18n, t } = useTranslation()
  const [lang, setLang] = useState(localStorage.getItem('i18nextLng') || 'zh')
  const [source, setSource] = useState('none')
  const [customImgs, setCustomImgs] = useState([])
  const [time, setTime] = useState(0)
  const [batchCount, setBatchCount] = useState(10)
  const history = useHistory()

  const changeLang = () => {
    if (i18n.resolvedLanguage === 'zh') {
      i18n.changeLanguage('en')
      setLang('en')
      return
    }
    if (i18n.resolvedLanguage === 'en') {
      i18n.changeLanguage('zh')
      setLang('zh')
      return
    }
  }

  const getImgs = async () => {
    try {
      const temporaryImgs = []
      const config = await transportLayer.getBackgroundConfig()
      const source = config.data.data.value.source
      setSource(source)
      if (source === 'none') {
        setAppBackground(backgroundDefaultImage)
        return
      }
      if (source === 'bing') {
        const bingImg = await transportLayer.getBackgroundImgList('bing')
        if (bingImg.data.data.docs.length === 0) {
          setAppBackground(backgroundDefaultImage)
          return
        }
        const bingImgUrl = await transportLayer.getBackgroundImgUrl(bingImg.data.data.docs[0].id)
        const img = await axios.get(bingImgUrl.data.data, { responseType: 'blob' })
        transformBlobToUrl(img.data)
        return
      }
      if (source === 'custom') {
        const customImg = await transportLayer.getBackgroundImgList('custom')
        if (customImg.data.data.docs.length === 0) {
          setAppBackground(backgroundDefaultImage)
          return
        }
        for (const i of customImg.data.data.docs) {
          const curImgUrl = await transportLayer.getBackgroundImgUrl(i.id)
          const img = await axios.get(curImgUrl.data.data, { responseType: 'blob' })
          temporaryImgs.push(img.data)
        }
        setTime(config.data.data.value.interval)
        setCustomImgs(temporaryImgs)
      }
      if(source === 'wallpaper') {
        const wallImg = await transportLayer.getBackgroundImgList('wallpaper')
        const wallImgUrl = await transportLayer.getBackgroundImgUrl(wallImg.data.data.docs[0].id)
        const img = await axios.get(wallImgUrl.data.data, { responseType: 'blob' })
        transformBlobToUrl(img.data)
        return
      }
    } catch (error) {
      setAppBackground(backgroundDefaultImage)
    }
  }

  const getDingTalkAuthCode = async () => {
    try {
      if (dd.env.platform !== 'notInDingTalk') {
        const { data: { data } } = await transportLayer.getCorpId()
        dd.ready(() => {
          dd.runtime.permission.requestAuthCode({
            corpId: data,
            onSuccess: async (result) => {
              try {
                await transportLayer.sendAutoCode(result.code)
                history.replace('/file')
              } catch (error) {
                openNotification(t('failed_to_login_authorized_by_dingtalk'))
              }
            },
            onFail: (err) => {
              openNotification(t('failed_to_login_authorized_by_dingtalk'))
            }
          })
        })
      }
    } catch (error) {
      openNotification(t('failed_to_get_corpId'))
    }
  }

  const getBatchCountConfig = async () => {
    try {
      const res = await transportLayer.getBatchCountConfig()
      setBatchCount(res.data.data.value.file_one_batch_num)
    } catch (error) {
      
    }
  }

  useEffect(() => {
    if (!Cookies.get('file_token')) {
      getDingTalkAuthCode()
    }
  }, [])

  useEffect(() => {
    !isMobile() && getImgs()
  }, [])

  useEffect(() => {
    getBatchCountConfig()
  }, [])

  useEffect(() => {
    let timer = null
    let index = 0
    try {
      if (source === 'custom' && customImgs.length > 0 && time !== 0) {
        transformBlobToUrl(customImgs[index++])
        if (customImgs.length > 1) {
          timer = setInterval(() => {
            if (index < customImgs.length) {
              transformBlobToUrl(customImgs[index++])
            } else {
              transformBlobToUrl(customImgs[0])
              index = 1
            }
          }, time * 1000);
        }
      }
    } catch (error) { }
    return () => {
      clearInterval(timer)
    }
  }, [source, customImgs, time])

  if (isMobile()) {
    return window.alert('请使用PC端打开');
  }

  return (
    <div className="App">
      <AppContext.Provider value={{ lang, changeLang, batchCount }}>
        <Switch>
          <Route path='/file' component={File} />
          <Route path='/login' component={Login} />
          <Route path='/agreement' component={Agreement} />
          <Route path='/policy' component={Policy} />
          <Redirect to='/login' />
        </Switch>
        <Info />
      </AppContext.Provider>
    </div>
  );
}

export default App;
