import { Select } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { areaCode as AREACODE } from '../../share/constant'
import './index.less'

const PrefixInput = (props) => {
  const { t } = useTranslation()
  const { placeholder, onMobileChange, onFocus, style, placeholderStyle, mobile, autoFocus, areaCode, onAreaCodeChange } = props
  return (
    <div className='custom-prefixinput-container'>
      <input type='text' required='required' onInput={onMobileChange} style={style} onFocus={onFocus} value={mobile} autoFocus={!!autoFocus} />
      <Select
        className='custom-prefixinput-select'
        bordered={false}
        suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
        style={{ width: 77 }}
        value={areaCode}
        dropdownMatchSelectWidth={false}
        options={AREACODE.map(i => ({
          value: i.area,
          label: t(i.label),
          code: i.code
        }))}
        optionLabelProp='code'
        onChange={onAreaCodeChange}
      />
      <span className='custom-prefixinput-placeholder' style={placeholderStyle}>{placeholder}</span>
    </div>
  )
}

export default PrefixInput
