import { useState, useEffect, useContext, useRef } from 'react'
import { Redirect } from 'react-router-dom'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import AppContext from '../../AppContext'
import transportLayer from '../../share/transportLayer'
import Desc from '../../components/desc'
import LoginDrawer from './LoginDrawer'
import en from '../../media/imgs/en.svg'
import zh from '../../media/imgs/zh.svg'
import { openNotification } from '../../share/utils'
import './index.less'

const Login = () => {
  const { t } = useTranslation()
  const onCloseRef = useRef()
  const { lang, changeLang } = useContext(AppContext)
  const [open, setOpen] = useState(false)
  const [autoSignUp, setAutoSignUp] = useState(false) // 是否开放注册

  const getUserConfig = async () => {
    try {
      const { data: { data } } = await transportLayer.getUserConfig()
      setAutoSignUp(data?.value.autoSignUp)
    } catch (error) {
      openNotification(t('failed_to_fetch_data'))
    }
  }

  const onClose = () => {
    onCloseRef.current?.onClose()
  }

  useEffect(() => {
    getUserConfig()
  }, [])

  if(Cookies.get('file_token')) {
    console.log('自动登录 跳转file页面')
    return <Redirect to='/file' push={false} />
  }

  return (
    <div className='login-page'>
      <div className='login-page-add-file hvr-backward' onClick={() => setOpen(true)} >
        <PlusOutlined style={{ margin: '0 21px 0 34px', fontSize: 32 }} />{t('add_file')}
      </div>
      <div className='login-page-desc'>
        <Desc />
      </div>
      <div className='login-page-register-or-login'>
        <div onClick={changeLang} >
          {
            lang === 'zh' ?
              <img src={en} alt='internationalization-icon' /> :
              <img src={zh} alt='internationalization-icon' />
          }
        </div>
        <span style={{ margin: '0 16px 4px 16px', fontSize: 16, color: 'rgba(0,0,0,0.15)' }}>|</span>
        <a href={`${window.location.origin}/admin`} target='_blank' rel='noreferrer' style={{ color: 'black' }}>{t('console')}</a>
        <div style={{ marginLeft: 16 }} onClick={() => setOpen(true)}>{autoSignUp ? t('register_login') : t('login')}</div>
        {open && (
          <CloseOutlined
            style={{ marginLeft: 16, fontSize: 16, fontWeight: 'bolder' }}
            onClick={onClose}
          />
        )}
      </div>
      <LoginDrawer open={open} setOpen={setOpen} autoSignUp={autoSignUp} onRef={onCloseRef} />
    </div>
  )
}

export default Login
