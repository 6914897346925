import { Drawer } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import A from '../../components/agreement'
import Desc from '../../components/desc'
import './index.less'

const Agreement = ({ history }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className='agreement-page-add-file' >
        <PlusOutlined style={{ margin: '0 21px 0 34px', fontSize: 32 }} />{t('add_file')}
      </div>
      <div className='agreement-page-desc'>
        <Desc />
      </div>
      <Drawer
        open={true}
        width={window.innerWidth * (800 / 1920)}
        onClose={() => history.push('/login')}
        maskStyle={{ background: 'transparent' }}
        className='agreement-page-drawer'
      >
        <A />
      </Drawer>
    </div>
  )
}

export default Agreement
