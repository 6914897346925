const Policy = () => {
  return (
    <div style={{ fontSize: 14 }}>
      <h1 style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>MeetingPad隐私政策</h1>
      <p style={{ display: 'flex', justifyContent: 'flex-end' }}>最近更新日期：2023年4月20日 生效日期：2023年4月20日</p>
      <div style={{ fontWeight: 'bold', marginBottom: 14 }}>
        上海金桥信息股份有限公司及其关联公司（以下简称“MeetingPad公司”或“我们”）深知个人信息对所有使用MeetingPad服务的个人用户（以下简称“用户”或“您”）而言的重要性，我们将通过本《MeetingPad隐私政策》（以下简称“本隐私政策”）向您说明我们如何保护您的信息。
      </div>
      <p>根据隐私政策内容需要，“MeetingPad”品牌下的服务称为“MeetingPad服务”或“MeetingPad”。</p>
      <p>本隐私政策旨在帮助您了解我们会收集哪些数据、为什么收集这些数据，会使用这些数据做些什么及如何保护这些数据。<strong>若您使用MeetingPad服务，即表示您认同我们在本政策中所述内容。在将您的信息用于本隐私权政策未涵盖的用途时，我们会事先征求您的同意。</strong></p>
      <p>本隐私政策适用于任何MeetingPad服务，包括《MeetingPad用户协议》期限内的您所使用的各项服务和软件的升级和更新。</p>
      <p>请您确保您向我们提供的个人信息，以及您授权我们在本隐私政策所述范围内收集、处理、使用、存储、共享、转让或公开披露相关个人信息，不会侵犯他人合法权益。</p>
      <p>本隐私政策将帮助您了解以下内容：</p>
      <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 14 }}>一、我们收集您的哪些个人信息</div>
      <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 14 }}>二、我们如何使用您的个人信息</div>
      <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 14 }}>三、我们如何共享、转让、披露您的个人信息</div>
      <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 14 }}>四、用户内容数据和公开信息</div>
      <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 14 }}>五、您的个人信息如何在全球范围内转移</div>
      <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 14 }}>六、我们如何保存您的个人信息</div>
      <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 14 }}>七、我们如何保护您的个人信息</div>
      <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 14 }}>八、您的权利</div>
      <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 14 }}>九、我们对Cookie和相关技术的使用</div>
      <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 14 }}>十、本隐私政策如何更新</div>
      <div style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 14 }}>十一、如何联系我们</div>
      <div>
        <h1>一、我们收集您的哪些个人信息</h1>
        <p>在您使用MeetingPad服务的过程中，我们将根据合法、正当、必要的原则收集您的个人信息。您的个人信息将被用于以下业务功能：</p>
        <p><strong>1. 注册服务</strong></p>
        <p><strong>当您注册使用MeetingPad服务时，您可以通过手机号或者MeetingPad公司允许的其它方式创建账号，您提交的手机号等其他信息用于您注册、登录、绑定账户、密码找回时接受验证码，且作为您与MeetingPad指定的联系方式之一，用来接受相关业务通知（如新功能上线、服务通知/变更等）。此外，您可完善相关的网络身份识别信息（如账号名称和密码等）。收集上述信息是为了帮助您完成注册。</strong></p>
        <p><strong>2. 实名认证信息</strong></p>
        <p><strong>在您使用MeetingPad服务需进行身份认证的功能时，根据中华人民共和国相关法律法规，您可能需要提供您的真实身份信息（电话号码）以完成实名验证。这些信息属于个人敏感信息，我们将采用行业内通行的方式，尽最大的商业努力来保护您个人敏感信息的安全。</strong></p>
        <p>3. 提供MeetingPad服务</p>
        <p>在您使用服务过程中我们会收集以下的信息：</p>
        <p>3.1 <strong>设备信息：</strong>我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的<strong>设备属性信息（例如设备型号、操作系统版本、唯一设备标识符、广告标识符IDFA等软硬件特征信息）。</strong>我们可能会将前面两类信息进行关联，以便我们能在不同设备上为您提供一致的服务。</p>
        <p>3.2 <strong>日志信息：</strong>当您使用我们的网站或客户端提供的服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。<strong>例如文件大小/类型、使用的语言、分享链接、应用/功能崩溃等行为的日志记录等。</strong></p>
        <p>3.3 <strong>用户账户的支持信息：</strong>基于您使用MeetingPad服务而产生的用户的咨询记录、报障记录和针对用户故障的排障过程<strong>（如通信或通话记录）</strong>，MeetingPad将通过记录、分析这些信息以便更及时响应您的帮助请求，以及用于改进服务。</p>
        <p><strong>请注意，单独的设备信息、日志信息、支持信息是无法识别特定自然人身份的信息。</strong>如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。</p>
        <p><strong>3.4 我们在向您提供业务功能或具体服务时，会按照本隐私政策以及相应的用户协议的约定收集、使用、存储、对外提供及保护您的信息；超出本隐私政策以及相应的用户协议约定收集您信息的，我们会另行向您说明信息收集的范围与目的，并征得您的同意后方收集提供相应服务所必要的个人信息。</strong></p>
        <p>3.5 我们为您提供的其他附加服务</p>
        <p>为向您提供您所选择使用的服务，或者保证服务的质量和体验，可能需要您授权开启操作系统权限。如果您不同意授权App获取相关操作系统权限，不会影响您使用我们提供的基本业务功能（基本业务功能所依赖的必要操作系统权限除外），但您可能无法获得附加服务给您带来的用户体验。您可在您的设备设置中逐项查看权限的状态，并可自行决定这些权限随时的开启或关闭。</p>
        <p>&#8226; <strong>存储空间权限：</strong>当您使用MeetingPad的本机文件查看预览、选择本机文件上传等功能时，为了向您提供该服务，我们会在获得您的明示同意后，访问您的<strong>存储空间</strong>。该信息属于敏感信息，拒绝提供该信息后仅会使您无法使用上述功能，但不影响您正常使用MeetingPad的其他功能。此外，您也可以随时在手机设置中关闭相关权限。</p>
        <p>请您注意，以上权限默认关闭状态，<strong>如您拒绝提供授权，将无法使用对应功能，但不影响您正常使用MeetingPad其他功能。</strong>您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</p>
        <p>4. 请您理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用您的个人信息无需征得您的授权同意：</p>
        <p>4.1 与国家安全、国防安全、 公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>4.2 出于维护个人信息主体或其他个人的生命、财产等重大合法权益的；</p>
        <p>4.3 与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
        <p>4.4 您自行向社会公众公开的，或从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p>4.5 用于维护MeetingPad相关服务的安全稳定运行所必需的，例如发现、处置MeetingPad相关服务的故障；</p>
        <p>4.6 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
        <p>4.7 法律法规规定的其他情形。</p>
      </div>
      <div>
        <h1>二、我们如何使用您的个人信息</h1>
        <p><strong>1. 我们还会出于如下目的，使用您提交以及我们收集的您信息：</strong></p>
        <p>1.1 为了向您提供服务，我们会向您发送信息、通知、邮件，包括但不限于为保证服务完成所必须的验证码、使用服务时所必要的推送通知，及依照法律、法规和监管规定的要求，将您提供的个人信息的真实性进行核验。</p>
        <p>1.2 我们向您提供内容上传、存储、下载、分享等服务，需要请求您授予<strong>读写存储权限</strong>的功能。您可以通过MeetingPad管理或删除已存储到MeetingPad账户的个人信息。</p>
        <p>1.3 我们可能以用户信息统计数据为基础，设计、开发、推广全新的服务；我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，以展示我们服务的整体使用趋势，但这些统计信息将不会包含您的任何身份识别信息。</p>
        <p>1.4 为提高您使用我们服务的安全性，确保操作环境安全与识别账号异常状态，保护您或其他第三人的人身财产安全免遭侵害，更好地预防网络漏洞、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或服务协议的情况，我们可能使用您的信息，来判断您账户及交易风险、进行身份验证、安全事件的检测及防范，并依法采取必要的记录、分析、处置措施。</p>
        <p>2. 如超出收集您信息时所声称的目的，或者在超出具有直接或合理关联的范围使用您信息前，我们会再次向您告知并征得您的明示同意。</p>
      </div>
      <div>
        <h1>三、我们如何共享、转让、披露您的个人信息</h1>
        <h1>共享</h1>
        <p>我们原则上不会与本公司以外的任何公司、组织和个人分享您的个人信息，但以下情形除外：</p>
        <p>1. 在约定情形下的共享：在获取您的明确同意或符合使用本服务之目的的情况下共享。</p>
        <p>2. 在法定情形下的共享：我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按行政、司法机关依法提出的要求，对外共享您的信息。</p>
        <p>3. 与关联公司间共享：为便于我们基于MeetingPad账户向您提供产品和服务，识别会员账户异常，保护您或其他用户或公众的人身财产信息安全免遭侵害，本隐私协议涉及收集的您的信息可能会与我们的关联公司和/或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。</p>
        <p>4. 与授权合作伙伴共享：我们可能委托受信赖的合作伙伴来提供服务，如推广服务、产品分析服务、技术支持服务等。因此我们可能会与合作伙伴共享您的某些您信息，以提供更好的客户服务和优化您体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息，并且只会共享提供服务所必要的您信息。我们的合作伙伴无权将共享的信息用于任何其他用途。</p>
        <p>目前，我们的授权合作伙伴包括如下类型：供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供基础技术服务、提供咨询、分析等专业服务。</p>
        <h1>转让</h1>
        <p>1. 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
        <p>2. 在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意；</p>
        <p>3. 在法定情形下的转让：满足法律法规、法律程序的要求或强制性的政府要求或司法裁定。</p>
        <h1>披露</h1>
        <p><strong>1. 我们仅会在以下情形下，公开披露您的个人信息：</strong></p>
        <p>1.1 获得您的明确同意后的披露；</p>
        <p>1.2 基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下的披露。</p>
        <p><strong>2. 以下情形中，共享、转让、公开披露您的信息无需事先征得您的授权同意：</strong></p>
        <p>2.1 与国家安全、国防安全、 公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>2.2 与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
        <p>2.3 出于维护个人信息主体或其他个人的生命、财产等重大合法权益的；</p>
        <p>2.4 您自行向社会公众公开的，或从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p>2.5 用于维护MeetingPad相关服务的安全稳定运行所必需的，例如发现、处置MeetingPad相关服务的故障；</p>
        <p>2.6 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
        <p>2.7 法律法规规定的其他情形。</p>
      </div>
      <div>
        <h1>四、用户内容数据和公开信息</h1>
        <p><strong>1. 内容数据：</strong>不同于用户个人信息，内容数据是指MeetingPad的用户利用MeetingPad的服务上传、下载、分发等通过MeetingPad的技术服务处理的数据。<strong>根据相关法律法规及用户协议，MeetingPad可能对不同性质的内容数据及公开信息进行一定程度的技术审查。但MeetingPad不会收集您储存或者分享的内容数据。</strong></p>
        <p>2. 对于用户内容数据和公开信息，MeetingPad将按如下方式处理：</p>
        <p><strong>2.1 用户内容数据</strong></p>
        <p>2.1.1 您通过MeetingPad提供的服务，上传、下载、储存和分享以及通过其他方式处理的数据，均为您的用户内容数据，您完全拥有您的用户内容数据。MeetingPad作为服务提供商，我们会在系统处理所需的合理时间内严格执行您的指示处理您的内容数据。除经您授权、按与您协商一致或执行明确的法律法规要求外，不对您的内容数据进行任何非授权的使用或披露。<strong>特别的，尽管MeetingPad不会非法或非经授权地访问、使用、改变或披露您在MeetingPad的用户内容数据，MeetingPad有权按照法律法规和有关监管机构的规范性文件的规定对您在MeetingPad上传、下载、储存、分享的用户内容数据进行主动或依举报地审查、监督，采取有效技术措施并做出相应行动。对于违反国家法律法规或侵犯第三人知识产权的用户数据文件，MeetingPad有权根据适用的规则进行处理，包括屏蔽、断开链接、中止或终止全部或部分服务，并向有关监管机关报告，由此造成的法律责任由您承担。</strong></p>
        <p>2.1.2 您应对您的用户内容数据来源及内容负责，MeetingPad提示您谨慎判断内容数据来源及内容的合法性。因您的用户内容数据内容违反法律法规、部门规章或国家政策而造成的全部结果及责任均由您自行承担。</p>
        <p>2.1.3 MeetingPad恪守对用户的安全承诺，根据适用的法律保护用户存储在MeetingPad的内容数据。</p>
        <p><strong>2.2 公开信息</strong></p>
        <p>2.2.1 公开信息是指您公开分享的任何信息，任何人都可以在使用和未使用MeetingPad服务期间查看或访问这些信息。</p>
      </div>
      <div>
        <h1>五、您的个人信息如何在全球范围内转移</h1>
        <p>1. 原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。</p>
        <p>2. 在获得您的授权同意后，您的个人信息可能会被转移到您使用服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。在此类情况下，我们会按照国家网信部门会同国务院有关部门制定的办法和相关标准进行安全评估，并符合其要求，以保证您的个人信息安全。</p>
      </div>
      <div>
        <h1>六、我们如何保存您的个人信息</h1>
        <p><strong>我们会采取合理可行的措施，尽力避免收集无关的您信息。我们只会在达成本隐私政策所述目的所需的期限内保留您的信息，超出上述保存期限后，我们会对您的信息进行删除或匿名化处理，但以下情形除外：</strong></p>
        <p>1. 在法律、法律程序、诉讼或政府主管部门强制性要求的情况下；</p>
        <p>2. 如果我们认为相关个人信息可能与任何正在进行或未来的法律程序有关；</p>
        <p>3. 为了行使MeetingPad公司的合法权利。</p>
      </div>
      <div>
        <h1>七、我们如何保护您的个人信息</h1>
        <p>1. 我们非常重视您的信息安全。我们已使用符合业界标准的安全防护措施保护您提供的个人信息， 防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息，以防止您的信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。</p>
        <p>2. 我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</p>
        <p>3. 互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。</p>
        <p>4. 我们将采取必要手段保护您提供给我们或我们收集的个人信息的安全，但如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的权益受损，非因我们的重大过失的，我们不对相应的损失承担责任。</p>
        <p>5. 在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知:安全事件的基本情况和可能的影响、我们已采取或将要采取 的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、电话等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
      </div>
      <div>
        <h1>八、您的权利</h1>
        <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>
        <p><strong>1. 访问您的个人信息</strong></p>
        <p>您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：</p>
        <p>账户信息——如果您希望访问或编辑您的账户中的个人资料信息和支付信息、更改您的密码、添加安全信息等，您可以通过访问MeetingPad网站、客户端执行此类操作。</p>
        <p><strong>2. 更正您的个人信息</strong></p>
        <p>当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过本服务提供的方式提出更正申请。</p>
        <p><strong>3. 删除您的个人信息</strong></p>
        <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <p>3.1 我们处理个人信息的行为违反法律法规；</p>
        <p>3.2 我们收集、使用您的个人信息，却未征得您的同意；</p>
        <p>3.3 我们处理个人信息的行为违反了与您的约定。</p>
        <p>若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体已获得您的独立授权。当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>
        <p><strong>4. 注销您的账户</strong></p>
        <p>若您需注销账户，请与管理员联系，在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，但法律法规另有规定的除外。</p>
        <p><strong>5. 响应您的上述请求</strong></p>
        <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。 我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
        <p><strong>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</strong>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
        <p>5.1 与国家安全、国防安全直接相关的；</p>
        <p>5.2 与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>5.3 与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>5.4 有充分证据表明您存在主观恶意或滥用权利的；</p>
        <p>5.5 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
        <p>5.6 涉及商业秘密的。</p>
      </div>
      <div>
        <h1>九、我们如何使用 Cookie 和同类技术</h1>
        <p>1. 为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好或购物篮内的商品等数据。</p>
        <p>2. Cookie通常不包含任何可能识别您身份的个人信息，但MeetingPad存储的有关您的个人信息可能与存储在Cookie中并从Cookie中获取的信息相关联。</p>
        <p>3. 我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。但请注意，如果停用Cookie，您可能无法享受最佳的服务体验。</p>
        <p>4. 很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do Not Track请求。目前，主要互联网标准组织尚未设立相关政策来规定网站 应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么我们的网站会尊重您的选择。</p>
      </div>
      <div>
        <h1>十、本隐私政策如何更新</h1>
        <p><strong>1. 我们有权随时对隐私政策进行更新及予以公告。</strong></p>
        <p><strong>2. 未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本隐私政策所做的任何变更。</strong></p>
        <p>3. 本隐私政策所指的重大变更包括但不限于：</p>
        <p>3.1 个人信息共享、转让或公开披露的主要对象发生变化；</p>
        <p>3.2 您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
        <p>3.3 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
        <p>3.4 个人信息安全影响评估报告表明存在高风险时。</p>
      </div>
      <div>
        <h1>十一、如何联系我们</h1>
        <p>如果您有任何疑问、意见或建议，请通过以下联系方式与我们联系：</p>
        <p>电子邮件:  MeetingPad@shgbit.com</p>
        <p>我们将尽快审核所涉问题，并在验证身份后及时处理，最长在不超过十五天或在法律法规规定期限内予以回复。</p>
      </div>
    </div>
  )
}

export default Policy
