import { useRef, useState, useCallback, useImperativeHandle } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Drawer } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Input from '../../components/input'
import PrefixInput from '../../components/prefixinput'
import transportLayer from '../../share/transportLayer'
import { codeRegExp, areaCode as AreaCode } from '../../share/constant'
import { hasFullAngle, openNotification } from '../../share/utils'
import './LoginDrawer.less'

const LoginDrawer = ({ open, setOpen, autoSignUp, onRef }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const timerRef = useRef()
  const remainTimeRef = useRef()
  const [mobile, setMobile] = useState('')
  const [areaCode, setAreaCode] = useState('china_mainland')
  const [code, setCode] = useState('')
  const [process, setProcess] = useState('mobile') // mobile code
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [remainTime, setRemainTime] = useState()
  const [codeCooling, setCodeCooling] = useState(false)
  const [loading, setLoading] = useState(false)
  const [codeErrMsg, setCodeErrMsg] = useState()
  remainTimeRef.current = remainTime

  const regionCode = AreaCode.find(i => i.area === areaCode)?.code || '+86'

  const handleNext = async () => {
    try {
      if (process === 'mobile') {
        if(!(/^[0-9]{1,20}$/.test(mobile)) || hasFullAngle(mobile)) {
          setShowErrorMessage(true)
          return
        }
        setLoading(true)
        const res = await transportLayer.getVerificationCode({ mobileNumber: mobile, regionCode, language: localStorage.getItem('i18nextLng') || 'zh' })
        timerRef.current && clearInterval(timerRef.current)
        setShowErrorMessage(false)
        setProcess('code')
        setCodeCooling(true)
        setRemainTime(res.data.data.remainTime)
        timerRef.current = setInterval(() => {
          if (remainTimeRef.current <= 0) {
            setCodeCooling(false)
            clearInterval(timerRef.current)
          } else {
            setRemainTime(remainTimeRef.current - 1)
          }
        }, 1000)
      }
      if (process === 'code') {
        if(code?.length !== 6) {
          setCodeErrMsg(null)
          setShowErrorMessage(true)
        }
        if (!codeRegExp.test(code)) {
          setCodeErrMsg(t('10003'))
          return
        }
        setLoading(true)
        await transportLayer.login({ mobileNumber: mobile, verificationCode: code, regionCode })
        setShowErrorMessage(false)
        setCodeErrMsg(null)
        history.replace('/file')
      }
    } catch (error) {
      if(error.config.url === '/sendVerifyCode') {
        if(error.response) {
          openNotification(t('10002'))
          return
        }
      }
      if(error.config.url === '/login') {
        if(error.response) {
          openNotification(t('10002'))
          return
        }
        if(error.data.code === 10003) {
          setCodeErrMsg(t('10003'))
        }
        if(error.data.code === 40009) {
          // setCodeErrMsg(t('40009'))
          openNotification(t('40009'))
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const getVerificationCodeAgain = async () => {
    try {
      if (codeCooling) return
      const { data } = await transportLayer.getVerificationCode({
        mobileNumber: mobile, regionCode, language: localStorage.getItem('i18nextLng') || 'zh'
      })
      setCodeCooling(true)
      setRemainTime(data.data.remainTime)
      timerRef.current = setInterval(() => {
        if (remainTimeRef.current <= 0) {
          setCodeCooling(false)
          clearInterval(timerRef.current)
        } else {
          setRemainTime(remainTimeRef.current - 1)
        }
      }, 1000)
    } catch (error) {
      if(error.response) {
        openNotification(t('10002'))
      }
    }
  }

  const goBack = useCallback(() => {
    setProcess('mobile')
    setShowErrorMessage(false)
    setCodeCooling(false)
    setCodeErrMsg(null)
    clearInterval(timerRef.current)
  }, [])

  const onFocus = useCallback(() => {
    setShowErrorMessage(false)
    setCodeErrMsg('')
  }, [])

  const onClose = () => {
    clearInterval(timerRef.current)
    setCode(null)
    setProcess('mobile')
    setShowErrorMessage(false)
    setRemainTime(null)
    setCodeCooling(false)
    setLoading(false)
    setCodeErrMsg(null)
    setOpen(false)
  }

  useImperativeHandle(onRef, () => {
    return {
      onClose
    }
  })

  return (
    <Drawer
      open={open}
      onClose={onClose}
      maskStyle={{ background: 'transparent' }}
      width={window.innerWidth * (800 / 1920)}
      closable={false}
      bodyStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      className='login-drawer'
    >
      <div className='login-drawer-form-container'>
        <div>{autoSignUp ? t('register_login') : t('login')}</div>
        <div>{autoSignUp ? t('register_login_by_mobile') : t('login_by_mobile')}</div>
        {
          process === 'mobile' && (
            <PrefixInput
              placeholder={t('mobile_number')}
              onFocus={onFocus}
              style={showErrorMessage ? { border: '2px solid rgb(255,68,64)', color: 'rgb(255,68,64)' } : {}}
              placeholderStyle={showErrorMessage ? { color: 'rgb(255,68,64)' } : {}}
              mobile={mobile}
              onMobileChange={e => setMobile(e.target.value)}
              areaCode={areaCode}
              onAreaCodeChange={(areaCode) => setAreaCode(areaCode)}
            />
          )
        }
        {
          process === 'code' && (
            <div style={{ position: 'relative' }}>
              <Input
                placeholder={t('6_verification_code')}
                onChange={e => setCode(e.target.value)}
                onFocus={onFocus}
                style={showErrorMessage || codeErrMsg ? { border: '2px solid rgb(255,68,64)', color: 'rgb(255,68,64)' } : {}}
                placeholderStyle={(showErrorMessage || codeErrMsg) ? { color: 'rgb(255,68,64)' } : {}}
                autoFocus={true}
              />
              <div
                style={{ position: 'absolute', right: 0, color: 'rgb(161,161,161)', fontSize: 12, cursor: codeCooling ? 'text' : 'pointer', top: 65 }}
                onClick={getVerificationCodeAgain}
              >
                {codeCooling ? t('get_code_after_seconds', { time: remainTime }) : t('send_code')}
              </div>
            </div>
          )
        }
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            margin: '50px 0 64px 0',
            justifyContent: (process === 'mobile' && showErrorMessage) || process === 'code' ? 'space-between' : 'flex-end',
          }}>
          {
            process === 'mobile' && showErrorMessage && (
              <div style={{ color: 'rgb(255,68,64)' }}><ExclamationCircleFilled style={{ marginRight: 4 }} />{t('invalid_mobile_number')}</div>
            )
          }
          {
            process === 'code' && (
              <div style={{ color: 'rgb(255,68,64)', position: 'relative' }}>
                {(showErrorMessage || codeErrMsg) && (
                  <div style={{ position: 'absolute', left: 0, top: -22, width: 140 }}>
                    <ExclamationCircleFilled style={{ marginRight: 4 }} />{showErrorMessage ? t('enter_6_code') : codeErrMsg}
                  </div>
                )}
                <Button style={{ width: 74, height: 40, color: 'rgb(161,161,161)' }} type='text' onClick={goBack}>{t('back')}</Button>
              </div>
            )
          }
          <Button
            style={{ width: 88, height: 40, borderRadius: 4, backgroundColor: 'rgb(0,0,0)', color: 'white' }}
            onClick={handleNext}
            loading={loading}
          >
            {t('next')}
          </Button>
        </div>
        {autoSignUp && <div className='login-drawer-form-note'>{t('auto_create')}</div>}
        <div className='login-drawer-form-note'>
          {
            autoSignUp ?
            (<>
              {/* {t('register_login_explain')}
              <a href={`${window.location.origin}/#/agreement`} target='_blank' rel='noreferrer'> {t('user_agreement')} </a>
              {t('and')}
              <a href={`${window.location.origin}/#/policy`} target='_blank' rel='noreferrer'> {t('privacy_policy')} </a>
              {t('register_login_explain_end')} */}
              {
                <>
                  {t('sign_up_or_sign_in_automatically_deemed_to_have_agreed_to_the')}
                  <a href={`${window.location.origin}/#/agreement`} target='_blank' rel='noreferrer'> {t('user_agreement')} </a>
                  {t('and')}
                  <a href={`${window.location.origin}/#/policy`} target='_blank' rel='noreferrer'> {t('privacy_policy')} </a>.
                </>
              }
            </>) :
            (<>
              {t('login_explain')}
              <a href={`${window.location.origin}/#/agreement`} target='_blank' rel='noreferrer'> {t('user_agreement')} </a>
              {t('and')}
              <a href={`${window.location.origin}/#/policy`} target='_blank' rel='noreferrer'> {t('privacy_policy')} </a>
              {t('login_explain_end')}
            </>)
          }
        </div>
      </div>
    </Drawer>
  )
}

export default LoginDrawer
