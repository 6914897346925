import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './en/translation.json'
import zh from './zh/translation.json'

const resources = {
  en: { translation: en },
  zh: { translation: zh }
}

i18n.use(
  LanguageDetector
).use(
  initReactI18next
).init({
  compatibilityJSON: 'v3',
  resources,
  debug: true,
  fallbackLng: 'zh',
  interpolation: { escapeValue: false },
  detection: {
    order: ['localStorage'],
    lookupQuerystring: 'lang'
  }
})

export default i18n
