import axios from "axios"
import Cookies from "js-cookie"
import { openNotification } from "./utils"
import backendI18n from './backend_i18n.json'

let zipkin = window.RUNTIME.zipkin;
const wrapAxios = require('zipkin-js-instrumentation-axios');
const { Tracer, ExplicitContext, BatchRecorder, ConsoleRecorder } = require('zipkin');
const ctxImpl = new ExplicitContext();
const localServiceName = zipkin && zipkin.localServiceName;
const remoteServiceName = zipkin && zipkin.remoteServiceName;
const { HttpLogger } = require('zipkin-transport-http');

const recorder = zipkin && zipkin.ip ? new BatchRecorder({
  logger: new HttpLogger({
    endpoint: `${zipkin.webappProtocol}://${zipkin.ip}:${zipkin.port}${zipkin.path}`
  })
}) : new ConsoleRecorder();
const tracer = new Tracer({ ctxImpl, recorder, localServiceName });

class TransportLayer {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: '/api/v1',
      headers: { 'X-Custom-Header': 'foobar' },
    })

    this.axiosInstance.interceptors.request.use((cfg) => {
      if (Cookies.get('file_token')) {
        return { ...cfg, headers: { ...cfg.headers, Authorization: `Bearer ${Cookies.get('file_token')}` } }
      }
      return cfg
    })

    this.axiosInstance.interceptors.response.use((response) => {
      if (response.data.code !== 0) {
        if(response.data.code !== 500 && response.data.code !== 10003) {
          if(backendI18n.zh[response.data.code]) {
            if(localStorage.getItem('i18nextLng')) {
              openNotification(
                backendI18n[localStorage.getItem('i18nextLng')]?.[response.data.code],
                { width: response.config.url === '/login' ? 370 : 330 }
              )
            } else {
              openNotification(backendI18n['zh'][response.data.code])
            }
          }
        }
        return Promise.reject(response)
      }

      return response
    }, (err) => {
      if (err.response && err.response.status === 401) {
        Cookies.remove('file_token', { path: '/' })
        window.location.reload()
      }
      return Promise.reject(err)
    })
    this.client = wrapAxios(this.axiosInstance, { tracer, remoteServiceName });
  }

  getVerificationCode({ mobileNumber, regionCode, language }) {
    return this.client.post('/sendVerifyCode', { mobileNumber, regionCode, language })
  }

  login({ mobileNumber, verificationCode, regionCode }) {
    return this.client.post('/login', { mobileNumber, verificationCode, regionCode })
  }

  createBatch(fileList) {
    return this.client.post('/batches', { fileList })
  }

  getBatchDetailById(id) {
    return this.client.get(`/batches/${id}`)
  }

  getBatches({ begin, end, page, size }) {
    return this.client.get('/batches', { params: { begin, end, page, size } })
  }

  getUploadUrl(id) {
    return this.client.get(`/files/${id}/puturl`)
  }

  updateUploadSingleFileStatus(id) {
    return this.client.patch(`/files/${id}`)
  }

  updateUploadAllFileStatus(pin) {
    return this.client.patch(`/batches/${pin}`)
  }

  getBackgroundConfig() {
    return this.client.get('/config/background')
  }

  getBackgroundImgList(keys) {
    return this.client.get('/backgroundImages', {
      params: keys === 'bing' ? { keys, page: 1, size: 100, sort: '-createdAt' } : { keys, page: 1, size: 100 }
    })
  }

  getBackgroundImgUrl(id) {
    return this.client.get(`/backgroundImages/${id}/geturl`)
  }

  checkExpire() {
    return this.client.get('/auth/check')
  }

  getUserConfig() {
    return this.client.get('/config/user')
  }

  getCorpId() {
    return this.client.get('/corpId')
  }

  sendAutoCode(authCode) {
    return this.client.get('/auth', { params: { authCode } })
  }

  getBatchCountConfig() {
    return this.client.get('/config/batchConfig')
  }

  deleteBatchById(id) {
    return this.client.delete('/user/batches', { data: { id } })
  }

  clearAllFiles() {
    return this.client.delete('/flush')
  }

  createBatchByHistory(body) {
    return this.client.post('/files/add', body)
  }

  deleteFileByBatch(body) {
    return this.client.post('/files/delete', body)
  }
}

export default new TransportLayer()
