import React, { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { Button, Drawer, Table, Upload, Popover, Checkbox, Modal, ConfigProvider, message, Tooltip } from 'antd'
import { PlusOutlined, UserOutlined, CloseOutlined, CaretRightOutlined, CaretDownOutlined, DeleteOutlined, FileAddOutlined } from '@ant-design/icons'
import moment from 'moment'
import _ from 'lodash'
import ClipboardJS from 'clipboard'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import AppContext from '../../AppContext'
import Progress from '../../components/progress'
import Desc from '../../components/desc'
import Agreement from '../../components/agreement'
import Policy from '../../components/policy'
import transportLayer from '../../share/transportLayer'
import { byteSize, timeSize, fileExtensions, mimeTypes, fileIconMap, startWithNumber, startWithLetter, startWithZhNumber, transferStatusText } from '../../share/constant'
import { isLimit100M, isLimitBatchCount, openNotification, showFileSize, compareLetterStr, compareZhNumberStr, isLimit255Byte } from '../../share/utils'
import emptyHistory from '../../media/imgs/empty-history.svg'
import en from '../../media/imgs/en.svg'
import zh from '../../media/imgs/zh.svg'
import transferError from '../../media/imgs/transfer-error.svg'
import './index.less'

const { Column } = Table

const File = ({ history }) => {
  const dropRef = useRef(false)
  const { t } = useTranslation()
  const { lang, changeLang, batchCount } = useContext(AppContext)
  const [transferHistoryOpen, setTransferHistoryOpen] = useState(false)
  const [transferPreviewOpen, setTransferPreviewOpen] = useState(false)
  const [agreementOpen, setAgreementOpen] = useState(false)
  const [policyOpen, setPolicyOpen] = useState(false)
  const [transferHistory, setTransferHistory] = useState({ docs: [], hasNextPage: false })
  const [subList, setSubList] = useState({})
  const [transferStatus, setTransferStatus] = useState('add') // add transfer finish error
  const [fileList, setFileList] = useState([])
  const [checked, setChecked] = useState(true)
  const [pin, setPin] = useState()
  const [loading, setLoading] = useState(false)
  const [tableParams, setTableParams] = useState({ page: 1, size: 15 })
  const [tableLoading, setTableLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [preTempProgress, setPreTempProgress] = useState(0)
  const [timeStamp, setTimeStamp] = useState(0)
  // const [preTimeStamp, setPreTimeStamp] = useState(0)
  const [expireTime, setExpireTime] = useState(moment().valueOf())  // 用来计算过期时间
  const [historyMask, setHistoryMask] = useState(false)
  const scrollElement = useRef()
  const progressRef = useRef(progress)
  progressRef.current = progress
  const preTempProgressRef = useRef(preTempProgress)
  preTempProgressRef.current = preTempProgress
  // const preTimeStampRef = useRef(timeStamp)
  // preTimeStampRef.current = preTimeStamp
  const totalSize = fileList.reduce((acc, cur) => acc + cur.size, 0)
  const hasFile = fileList.length > 0
  // const transferRate = progress / (timeStamp / 1000)
  const transferRate = progress / timeStamp

  const formatUploadTime = (time) => {
    const today = moment()
    const yesterday = moment().subtract(1, 'days')
    const target = moment.unix(time)
    const isToday = moment(target).isSame(today, 'day')
    const isYesterday = moment(target).isSame(yesterday, 'day')
    if (isToday) {
      return target.format(`[${t('today')}] HH:mm`)
    }
    if (isYesterday) {
      return target.format(`[${t('yesterday')}] HH:mm`)
    }
    return target.format('YYYY/MM/DD HH:mm')
  }

  const removeFile = (file) => setFileList(fileList.filter(f => f.name !== file.name))

  const logout = useCallback(() => {
    Modal.confirm({
      title: t('confirm_logout'),
      onOk: () => {
        Cookies.remove('file_token', { path: '/' })
        history.replace('/login')
      },
      cancelText: t('cancel'),
      okText: t('confirm'),
      okButtonProps: { style: { color: 'black' } }
    })
  }, [])

  const copy = () => {
    return new Promise((resolve, reject) => {
      const clipboard = new ClipboardJS('.file-page-copy-pin-code-btn', {
        text: function (trigger) {
          return trigger.getAttribute('aria-label');
        }
      })
      clipboard.on('success', () => {
        resolve(clipboard)
        openNotification(t('copy_success'))
      })
      clipboard.on('error', () => {
        reject(clipboard)
        openNotification(t('copy_fail'))
      })
    })
  }

  const handleClickPreview = useCallback(() => {
    setTransferHistoryOpen(false)
    setAgreementOpen(false)
    setPolicyOpen(false)
    setTransferPreviewOpen(pre => !pre)
  }, [])

  const handleClickHistory = useCallback(() => {
    setTransferHistoryOpen(pre => !pre)
    setTransferPreviewOpen(false)
    setAgreementOpen(false)
    setPolicyOpen(false)
  }, [])

  const handleClickAgreement = useCallback(() => {
    setAgreementOpen(pre => !pre)
    setPolicyOpen(false)
    setTransferHistoryOpen(false)
  }, [])

  const handleClickPolicy = useCallback(() => {
    setPolicyOpen(pre => !pre)
    setAgreementOpen(false)
    setTransferHistoryOpen(false)
  }, [])

  const handleClickClearAllFiles = () => {
    if (transferHistory.docs.length === 0) return
    Modal.confirm({
      title: t('empty_all_files'),
      okText: t('confirm'),
      cancelText: t('cancel'),
      zIndex: 1002,
      onOk: async () => {
        try {
          await transportLayer.clearAllFiles()
          setTableLoading(true)
          const { data: { data } } = await transportLayer.getBatches({ page: 1, size: 15 })
          setTransferHistory({ docs: data.docs, hasNextPage: data.hasNextPage })
          setTableParams({ page: 1, size: 15 })
          setSubList({})
          message.success(t('clear_completed'))
        } catch (error) {

        } finally {
          setTableLoading(false)
        }
      }
    })
  }

  const handleDeleteBatchById = (id) => Modal.confirm({
    title: t('delete_file_selected_batch'),
    okText: t('confirm'),
    cancelText: t('cancel'),
    zIndex: 1002,
    onOk: async () => {
      try {
        await transportLayer.deleteBatchById(id)
        setTableLoading(true)
        const { data: { data } } = await transportLayer.getBatches({ page: 1, size: 15 })
        setTransferHistory({ docs: data.docs, hasNextPage: data.hasNextPage })
        setTableParams({ page: 1, size: 15 })
        setSubList({})
        message.success(t('delete_completed'))
      } catch (error) {
        console.log("handleDeleteBatchById: ", error)
      } finally {
        setTableLoading(false)
      }
    }
  })

  const handleDeleteFileByBatch = (batchId, fileName) => Modal.confirm({
    title: t("sure_to_delete_file", { name: fileName }),
    okText: t('confirm'),
    cancelText: t('cancel'),
    zIndex: 1002,
    onOk: async () => {
      try {
        setTableLoading(true)
        await transportLayer.deleteFileByBatch({ batch: batchId, fileName })
        const { data: { data } } = await transportLayer.getBatchDetailById(batchId)
        setSubList(preSubList => {
          let newSubList = {...preSubList}
          newSubList[batchId] = data
          return newSubList
        })
        message.success(t('delete_completed'))
      } catch (error) {
        console.log("handleDeleteFileByBatch_error", error)
      } finally {
        setTableLoading(false)
      }
    }
  })

  const reset = useCallback(() => {
    setTransferStatus('add')
    setFileList([])
    setChecked(true)
    setPin(null)
    setProgress(0)
    setPreTempProgress(0)
    setTimeStamp(0)
    // setPreTimeStamp(0)
    setTransferPreviewOpen(false)
    setAgreementOpen(false)
    setPolicyOpen(false)
  }, [])

  const getTransferHistory = async () => {
    try {
      setTableLoading(true)
      const { data: { data } } = await transportLayer.getBatches(tableParams)
      setTransferHistory((({ docs }) => ({ docs: [...docs, ...data.docs], hasNextPage: data.hasNextPage })))
      setTableLoading(false)
    } catch (error) {

    }
  }

  const onScrollCapture = (e) => {
    if ((e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop < 2) && transferHistory.hasNextPage) {
      setTableParams(({ page, size }) => ({ page: page + 1, size }))
    }
  }

  const beforeUpload = async (file, fList) => {
    if (file === fList[0]) {
      const filenameList = fileList.map(f => f.name.toLowerCase())
      const fnameList = fList.map(f => f.name.toLowerCase())
      const currentFileSize = new Set([...filenameList, ...fnameList]).size // 去重后的文件数量
      if (!isLimitBatchCount(currentFileSize, batchCount)) {
        openNotification(t('file_against_rules'))
        return false
      }
      for (const f of fList) {
        if (!mimeTypes.includes(f.type) || !isLimit100M(f.size) || !isLimit255Byte(f.name)) {
          openNotification(t('file_against_rules'))
          return false
        }
      }
      setFileList(preFilelist => {
        const newFileList = [...preFilelist.filter(p => !fnameList.includes(p.name.toLowerCase())), ...fList]
        const number = [], letter = [], zhNumber = [], others = []
        for (const f of newFileList) {
          if (startWithNumber.test(f.name)) {
            number.push(f)
          } else if (startWithLetter.test(f.name)) {
            letter.push(f)
          } else if (startWithZhNumber.test(f.name)) {
            zhNumber.push(f)
          } else {
            others.push(f)
          }
        }
        number.sort((preFile, nextFile) => {
          const pre = preFile.name.match(/^\d+/)
          const next = nextFile.name.match(/^\d+/)
          return pre[0] - next[0]
        })
        letter.sort((preFile, nextFile) => {
          const pre = preFile.name.match(/^[a-z]+/i)
          const next = nextFile.name.match(/^[a-z]+/i)
          return compareLetterStr(pre[0], next[0])
        })
        zhNumber.sort((preFile, nextFile) => {
          const pre = preFile.name.match(/^[一二三四五六七八九十][一二三四五六七八九十百千万亿]*/)
          const next = nextFile.name.match(/^[一二三四五六七八九十][一二三四五六七八九十百千万亿]*/)
          return compareZhNumberStr(pre[0], next[0])
        })
        return [...zhNumber, ...number, ...letter, ...others]
      })
    }
    return false
  }

  const beforeUpload2 = async (file, fList) => {
    const filenameList = fileList.map(f => f.name.toLowerCase())
    const fnameList = fList.map(f => f.name.toLowerCase())
    const currentFileSize = new Set([...filenameList, ...fnameList]).size // 去重后的文件数量
    if (!isLimitBatchCount(currentFileSize, batchCount)) {
      openNotification(t('file_against_rules'))
      return false
    }
    for (const f of fList) {
      if (!mimeTypes.includes(f.type) || !isLimit100M(f.size) || !isLimit255Byte(f.name)) {
        openNotification(t('file_against_rules'))
        return false
      }
    }
    setFileList(preFilelist => {
      const newFileList = [...preFilelist.filter(p => !fnameList.includes(p.name.toLowerCase())), ...fList]
      const number = [], letter = [], zhNumber = [], others = []
      for (const f of newFileList) {
        if (startWithNumber.test(f.name)) {
          number.push(f)
        } else if (startWithLetter.test(f.name)) {
          letter.push(f)
        } else if (startWithZhNumber.test(f.name)) {
          zhNumber.push(f)
        } else {
          others.push(f)
        }
      }
      number.sort((preFile, nextFile) => {
        const pre = preFile.name.match(/^\d+/)
        const next = nextFile.name.match(/^\d+/)
        return pre[0] - next[0]
      })
      letter.sort((preFile, nextFile) => {
        const pre = preFile.name.match(/^[a-z]+/i)
        const next = nextFile.name.match(/^[a-z]+/i)
        return compareLetterStr(pre[0], next[0])
      })
      zhNumber.sort((preFile, nextFile) => {
        const pre = preFile.name.match(/^[一二三四五六七八九十][一二三四五六七八九十百千万亿]*/)
        const next = nextFile.name.match(/^[一二三四五六七八九十][一二三四五六七八九十百千万亿]*/)
        return compareZhNumberStr(pre[0], next[0])
      })
      return [...zhNumber, ...number, ...letter, ...others]
    })
    return false
  }

  const beforeUpload3 = async (file, fList, preListInfo) => {
    if(file === fList[0]) {
      try {
        const fnameList = fList.map(f => f.name.toLowerCase())
        const { data: { data } } = await transportLayer.getBatchDetailById(preListInfo.id)
        const currentFileSize = new Set(fnameList).size + data.length
        if (!isLimitBatchCount(currentFileSize, batchCount)) {
          openNotification(t('file_against_rules'))
          return false
        }
        for (const f of fList) {
          if (!mimeTypes.includes(f.type) || !isLimit100M(f.size) || !isLimit255Byte(f.name)) {
            openNotification(t('file_against_rules'))
            return false
          }
        }
        const existedFile = data.find(i => fnameList.includes(i.fileName.toLowerCase()))
        if(!!existedFile) {
          openNotification(t('file_already_exists', { name: existedFile.fileName }))
          return false
        }
        let number = [], letter = [], zhNumber = [], others = []
        const fileListInfo = [...data.map(i => ({ name: i.fileName, fileId: i.fileID })), ...fList.map(i => ({ name: i.name, mimeType: i.type, size: i.size }))]
        for (const f of fileListInfo) {
          if (startWithNumber.test(f.name)) {
            number.push(f)
          } else if (startWithLetter.test(f.name)) {
            letter.push(f)
          } else if (startWithZhNumber.test(f.name)) {
            zhNumber.push(f)
          } else {
            others.push(f)
          }
        }
        number.sort((preFile, nextFile) => {
          const pre = preFile.name.match(/^\d+/)
          const next = nextFile.name.match(/^\d+/)
          return pre[0] - next[0]
        })
        letter.sort((preFile, nextFile) => {
          const pre = preFile.name.match(/^[a-z]+/i)
          const next = nextFile.name.match(/^[a-z]+/i)
          return compareLetterStr(pre[0], next[0])
        })
        zhNumber.sort((preFile, nextFile) => {
          const pre = preFile.name.match(/^[一二三四五六七八九十][一二三四五六七八九十百千万亿]*/)
          const next = nextFile.name.match(/^[一二三四五六七八九十][一二三四五六七八九十百千万亿]*/)
          return compareZhNumberStr(pre[0], next[0])
        })
        const newFileListInfo = [...zhNumber, ...number, ...letter, ...others].map((i, index) => ({ ...i, weight: index }))
        const batchInfo = {
          batch: preListInfo.id,
          fileList: newFileListInfo.filter(i => !i.fileId).map(i => ({ fileName: i.name, mimeType: i.mimeType, size: i.size, weight: i.weight })),
          rankList: newFileListInfo.filter(i => !!i.fileId).map(i => ({ fileId: i.fileId, weight: i.weight }))
        }
        setExpireTime(preListInfo.createdAt * 1000)
        const addBatchResponse = await transportLayer.createBatchByHistory(batchInfo)
        const { files, pin } = addBatchResponse.data.data
        setPin(pin)
        setTransferStatus('transfer')
        setFileList(() => {
          let number = [], letter = [], zhNumber = [], others = []
          for (const f of fList) {
            if (startWithNumber.test(f.name)) {
              number.push(f)
            } else if (startWithLetter.test(f.name)) {
              letter.push(f)
            } else if (startWithZhNumber.test(f.name)) {
              zhNumber.push(f)
            } else {
              others.push(f)
            }
          }
          number.sort((preFile, nextFile) => {
            const pre = preFile.name.match(/^\d+/)
            const next = nextFile.name.match(/^\d+/)
            return pre[0] - next[0]
          })
          letter.sort((preFile, nextFile) => {
            const pre = preFile.name.match(/^[a-z]+/i)
            const next = nextFile.name.match(/^[a-z]+/i)
            return compareLetterStr(pre[0], next[0])
          })
          zhNumber.sort((preFile, nextFile) => {
            const pre = preFile.name.match(/^[一二三四五六七八九十][一二三四五六七八九十百千万亿]*/)
            const next = nextFile.name.match(/^[一二三四五六七八九十][一二三四五六七八九十百千万亿]*/)
            return compareZhNumberStr(pre[0], next[0])
          })
          return [...zhNumber, ...number, ...letter, ...others]
        })
        setHistoryMask(true)
        let index = 0
        const totalSize = fList.reduce((acc, cur) => acc + cur.size, 0)
        const beginUnix = moment().unix()
        while (index < files.length) {
          const urlRes = await transportLayer.getUploadUrl(files[index].id)
          const curFile = fList.find(f => f.name === files[index].fileName)
          await axios.put(urlRes.data.data.url, curFile, {
            onUploadProgress: (e) => {
              const plusProgress = e.loaded - preTempProgressRef.current
              setProgress(pre => pre + plusProgress)
              setTimeStamp(moment().unix() - beginUnix)
              if (e.loaded === e.total) {
                setPreTempProgress(0)
              } else {
                setPreTempProgress(e.loaded)
              }
              if (progressRef.current + plusProgress >= totalSize) {
                setTransferStatus('finish')
                setTransferPreviewOpen(true)
                setTransferHistoryOpen(false)
                setAgreementOpen(false)
                setPolicyOpen(false)
                setTransferHistory({ docs: [], hasNextPage: false })
                setTableParams({ page: 1, size: 15 })
                setSubList({})
              }
            }
          })
          await transportLayer.updateUploadSingleFileStatus(files[index].id)
          index++
        }
        try {
          await transportLayer.updateUploadAllFileStatus(pin)
        } catch (error) {
          openNotification(t('failed_to_update_status'))
        }
        
      } catch (error) {
        console.log('beforeUpload3_error', error)
        setTransferStatus('error')
        if (error.data) {
          if ([24001, 30001, 60001, 60003].includes(error.data.code)) {
            return
          }
        }
        openNotification(t('failed_to_upload'))
      } finally {
        setHistoryMask(false)
      }
    }

    return false
  }

  const onDrop = (e) => {
    try {
      const filelist = Array.from(e.dataTransfer.files)
      const fileTypeMap = filelist.map(f => f.type)
      if (fileTypeMap.every(t => t === '')) {
        openNotification(t('file_against_rules'))
        return
      } else {
        dropRef.current = true
        if (fileTypeMap.some(t => t === '')) {
          openNotification(t('file_against_rules'))
          return
        }
        beforeUpload2(filelist[0], filelist)
      }
    } catch (error) {

    }
  }

  const onFileChange = ({ file, fileList }) => {
    if (dropRef.current) {
      dropRef.current = false
      return
    }
    if (file.uid === fileList[0].uid) {
      beforeUpload2(fileList[0], fileList.map(i => i.originFileObj))
    }
  }

  const beginTransfer = async () => {
    try {
      if (!checked) {
        openNotification(t('read_user_agreement_and_privacy_policy'), { width: 380 })
        return
      }
      setLoading(true)
      setHistoryMask(true)
      const preUploadFileListInfo = fileList.map((f, i) => ({
        fileName: f.name,
        mimeType: f.type,
        size: f.size,
        weight: i
      }))
      setExpireTime(Date.now())
      const { data: { data: { files, pin } } } = await transportLayer.createBatch(preUploadFileListInfo)
      setLoading(false)
      setPin(pin)
      setTransferStatus('transfer')
      let index = 0
      const beginUnix = moment().unix()
      while (index < files.length) {
        const urlRes = await transportLayer.getUploadUrl(files[index].id)
        const curFile = fileList.find(f => f.name === files[index].fileName)
        await axios.put(urlRes.data.data.url, curFile, {
          onUploadProgress: e => {
            const plusProgress = e.loaded - preTempProgressRef.current
            // const plusTimeStamp = e.timeStamp.toFixed(0) - preTimeStampRef.current
            setProgress(pre => pre + plusProgress)
            // setTimeStamp(pre => pre + plusTimeStamp)
            setTimeStamp(moment().unix() - beginUnix)
            if (e.loaded === e.total) {
              setPreTempProgress(0)
              // setPreTimeStamp(0)
            } else {
              setPreTempProgress(e.loaded)
              // setPreTimeStamp(e.timeStamp.toFixed(0))
            }
            if (progressRef.current + plusProgress >= totalSize) {
              setTransferStatus('finish')
              setTransferPreviewOpen(true)
              setTransferHistoryOpen(false)
              setAgreementOpen(false)
              setPolicyOpen(false)
              setTransferHistory({ docs: [], hasNextPage: false })
              setTableParams({ page: 1, size: 15 })
              setSubList({})
            }
          }
        })
        await transportLayer.updateUploadSingleFileStatus(files[index].id)
        index++
      }
      transportLayer.updateUploadAllFileStatus(pin).catch(() => {
        openNotification(t('failed_to_update_status'))
      })
    } catch (error) {
      setLoading(false)
      setTransferStatus('error')
      if (error.data) {
        if ([24001, 30001, 60001, 60003].includes(error.data.code)) {
          return
        }
      }
      openNotification(t('failed_to_upload'))
    } finally {
      setHistoryMask(false)
    }
  }

  const expandedRowRender = (record) => {
    return (
      <ConfigProvider renderEmpty={() => null} >
        <Table
          showHeader={false}
          pagination={false}
          rowKey='fileName'
          dataSource={subList?.[record?.id] || []}
        >
          <Column
            title={t('file_name')}
            key='fileName'
            dataIndex='fileName'
            width='25%'
            render={(fileName, record) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={fileIconMap[record.mimeType]} alt='icon' style={{ margin: '0 16px 0 -40px' }} />
                <div>
                  <div style={{ wordBreak: 'break-all' }}>{fileName}</div>
                  <div>{showFileSize(record.size)}</div>
                </div>
              </div>
            )}
          />
          <Column
            title={t('upload_time')}
            key='uploadAt'
            dataIndex='uploadAt'
            render={(uploadAt) => typeof uploadAt === 'number' && moment.unix(uploadAt).format('YYYY/MM/DD HH:mm')}
          />
          <Column
            title={t('action')}
            key={'action'}
            dataIndex='action'
            align='right'
            render={(text, records) => {
              return (
                <Tooltip title={t('delete_files')}>
                  <DeleteOutlined
                    style={{ fontSize: 16, cursor: 'pointer' }}
                    onClick={() => handleDeleteFileByBatch(record.id, records.fileName)}
                  />
                </Tooltip>
              )
            }}
          />
        </Table>
      </ConfigProvider>
    )
  }

  const onExpand = async (expanded, record) => {
    try {
      if (expanded && !subList[record.id]) {
        setTableLoading(true)
        const { data: { data } } = await transportLayer.getBatchDetailById(record.id)
        setSubList(preSubList => ({ ...preSubList, [record.id]: data }))
      }
    } catch (error) {
      openNotification(t('failed_to_fetch_data'))
    } finally {
      setTableLoading(false)
    }
  }

  useEffect(() => {
    if (tableParams.page !== 1 && transferHistoryOpen) {
      getTransferHistory()
    }
  }, [tableParams, transferHistoryOpen])

  useEffect(() => {
    transportLayer.checkExpire()
  }, [])

  if (!Cookies.get('file_token')) {
    console.log('无file_token 跳转login页面')
    return <Redirect to='/login' push={false} />
  }

  return (
    <div className='file-page'>
      <div
        className={`file-page-upload-panel-container ${hasFile ? '' : 'move-forward'}`}
        style={{
          width: hasFile ? 310 : 360,
          // height: hasFile ? localStorage.getItem('i18nextLng') === 'en' && transferStatus === 'add' ? 468 : 452 : 80,
          height: hasFile ? 452 : 80,
          borderRadius: hasFile ? 12 : 40,
          top: hasFile ? 'calc(50% - 226px)' : 'calc(50% - 40px)'
        }}
      >
        {
          !hasFile && (
            <Upload.Dragger
              multiple={true}
              onDrop={onDrop}
              showUploadList={false}
              // beforeUpload={beforeUpload}
              beforeUpload={() => false}
              accept={fileExtensions.join()}
              onChange={onFileChange}
              fileList={[]}
            >
              <div className='upload-init-panel' onClick={() => setTransferStatus('add')} >
                <PlusOutlined style={{ margin: '0 21px 0 34px', fontSize: 32 }} />
                {t('add_file')}
              </div>
            </Upload.Dragger>
          )
        }
        {
          hasFile && (
            <div className='upload-filelist-panel'>
              {
                transferStatus === 'add' && (
                  <>
                    <div className='upload-panel-header'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>{t('file_transfer')}</div>
                        <div style={{ fontSize: 12, color: 'rgb(161,161,161)', marginLeft: 12, fontWeight: 'normal' }}>
                          {/* {t('size_in_total', { size: showFileSize(totalSize) })} */}
                          {t('total_files_count_and_size', { count: fileList.length, size: showFileSize(totalSize) })}
                        </div>
                      </div>
                      <Upload
                        fileList={[]}
                        multiple={true}
                        showUploadList={false}
                        accept={fileExtensions.join()}
                        beforeUpload={beforeUpload}
                      >
                        <div
                          className='upload-panel-upload-inner-container'
                          style={{ backgroundColor: 'rgb(240,197,79)' }}
                        >
                          <PlusOutlined style={{ fontSize: 15 }} />
                        </div>
                      </Upload>
                    </div>
                    <Upload.Dragger
                      multiple={true}
                      onDrop={onDrop}
                      onChange={onFileChange}
                      showUploadList={false}
                      // beforeUpload={beforeUpload}
                      fileList={[]}
                      beforeUpload={() => false}
                      openFileDialogOnClick={false}
                      accept={fileExtensions.join()}
                    >
                      <div className='upload-panel-file-list'>
                        {
                          fileList.map(f => (
                            <div key={f.uid} className='filelist-container'>
                              <div>
                                <img width='24' src={fileIconMap[f.type]} alt='icon' />
                                <div style={{ marginLeft: 10, width: 200 }}>
                                  <div style={{ fontSize: 12, fontWeight: 'bold', width: 200, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} title={f.name}>{f.name}</div>
                                  <div style={{ fontSize: 10 }}>{showFileSize(f.size)}</div>
                                </div>
                              </div>
                              <div onClick={() => removeFile(f)} className='filelist-remove-icon'>
                                <CloseOutlined style={{ fontSize: 10, color: 'rgb(161,161,161)', cursor: 'pointer' }} />
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </Upload.Dragger>
                    <div className='upload-panel-transfer-rules'>
                      <div><div>{t('expiration__date')}</div><div>{t('7_day')}</div></div>
                      <div>
                        <div
                          title={t('limited_format')}
                          style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        >
                          {t('limited_format')}
                        </div>
                        <div
                          title={t('access_file_type')}
                          style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        >
                          {t('access_file_type')}
                        </div>
                      </div>
                      <div><div>{t('limited_size')}</div><div>100 MB</div></div>
                      <div>
                        <div
                          title={t('limited_count')}
                          style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        >
                          {t('limited_count')}
                        </div>
                        <div
                          title={t('batch_items', { count: batchCount })}
                          style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        >
                          {t('batch_items', { count: batchCount })}
                        </div>
                      </div>
                      <div>
                        <div>
                          {/* {t('file_upload_explain')} */}
                          {t('i_agree_to_the')}
                          <span
                            style={{ color: '#f0c54f', cursor: 'pointer' }}
                            onClick={handleClickAgreement}
                          >
                            {` ${t('user_agreement')} `}
                          </span>,
                          {/* {t('and')} */}
                          <span
                            style={{ color: '#f0c54f', cursor: 'pointer' }}
                            onClick={handleClickPolicy}
                          >
                            {` ${t('privacy_policy')} `}
                          </span>
                          {/* {t('file_upload_explain_end')} */}
                          {t('and_be_responsible_for_the_files_i_share')}
                        </div>
                        <Checkbox style={{ marginLeft: 14 }} onChange={(e) => setChecked(e.target.checked)} checked={checked} />
                      </div>
                      <Button
                        type='primary'
                        shape='round'
                        onClick={beginTransfer}
                        style={{ width: 150, height: 40, color: 'black', fontSize: 14, marginLeft: '50%', transform: 'translateX(-50%)' }}
                        loading={loading}
                      >
                        {t('begin_upload')}
                      </Button>
                    </div>
                  </>
                )
              }
              {
                (transferStatus === 'transfer' || transferStatus === 'finish' || transferStatus === 'error') && (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 20px', position: 'relative' }}>
                      {
                        transferStatus === 'error' ?
                          <img src={transferError} alt='transfer-error-icon' width='192' height='192' style={{ transform: 'translateY(-15px) scale(0.7, 0.7)' }} /> :
                          <Progress num={progress / totalSize < 1 ? (progress / totalSize * 100).toFixed(0) : 100} />
                      }
                      <div style={{ fontSize: 16, fontWeight: 'bold', transform: 'translateY(-35px)' }}>
                        {t(transferStatusText[transferStatus])}
                      </div>
                      <div style={{ fontSize: 12, color: 'rgb(161,161,161)', margin: '8px 0 24px 0', transform: 'translateY(-35px)' }}>
                        {
                          transferStatus === 'transfer' ? (
                            <>
                              <span style={{ cursor: 'pointer' }} onClick={handleClickPreview}>{t('count_files', { count: fileList.length })} &gt;</span>
                              <span style={{ margin: '0 10px' }}>|</span>
                              <span>{showFileSize(totalSize)}</span>
                            </>
                          ) : (
                            <span style={{ cursor: 'pointer' }} onClick={handleClickPreview}>{t('transfer_preview')} &gt;</span>
                          )
                        }
                      </div>
                      <div style={{ fontSize: 13, fontWeight: 'bold', alignSelf: 'flex-start', transform: 'translateY(-35px)' }}>{t('transfer_pin')}</div>
                      <div
                        style={{
                          fontSize: 40,
                          fontWeight: 'bold',
                          border: '1px solid rgb(225,225,225)',
                          borderRadius: 12,
                          width: '100%',
                          height: 110,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '16px 0 20px',
                          transform: 'translateY(-35px)'
                        }}
                      >
                        {pin}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          transform: 'translateY(-35px)',
                          justifyContent: transferStatus === 'transfer' ? 'center' : 'space-between',
                        }}
                      >
                        <Button
                          type='primary'
                          style={{ width: 130, height: 40, color: 'black', borderRadius: 40 }}
                          onClick={() => copy().then((clipboard) => clipboard.destroy()).catch((clipboard) => clipboard.destroy())}
                          className='file-page-copy-pin-code-btn'
                          aria-label={`${pin}`}
                          disabled={!pin}
                        >
                          {t('copy')}
                        </Button>
                        {
                          (transferStatus === 'finish' || transferStatus === 'error') && (
                            <Button
                              style={{ width: 130, height: 40, color: 'black', borderRadius: 40 }}
                              onClick={reset}
                            >
                              {t('transfer_again')}
                            </Button>
                          )
                        }
                      </div>
                      <div style={{ fontSize: 10, position: 'absolute', letterSpacing: 1, top: 95, fontWeight: 'bold' }}>
                        {progress >= totalSize || timeStamp === 0 || transferStatus === 'error' ?
                          '' :
                          transferRate < byteSize.KB ? `${transferRate.toFixed(2)} B/s` : transferRate < byteSize.MB ? `${(transferRate / byteSize.KB).toFixed(2)} KB/s` : `${(transferRate / byteSize.MB).toFixed(2)} MB/s`
                        }
                      </div>
                    </div>
                  </>
                )
              }
            </div>
          )
        }
      </div>
      {!hasFile && (<div className='file-page-desc'><Desc /></div>)}
      <div className='file-page-bar'>
        <div onClick={changeLang}>
          {
            lang === 'zh' ?
              <img src={en} alt='internationalization-icon' style={{ marginLeft: 16 }} /> :
              <img src={zh} alt='internationalization-icon' style={{ marginLeft: 16 }} />
          }
        </div>
        <span style={{ margin: '0 16px 4px 16px', fontSize: 16, color: 'rgba(0,0,0,0.15)' }}>|</span>
        <a style={{ color: 'black' }} href={`${window.location.origin}/admin`} target='_blank' rel='noreferrer'>{t('console')}</a>
        {!transferHistoryOpen && (<span style={{ margin: '0 12px 0 18px' }} onClick={handleClickHistory}>{t('transfer_history')}</span>)}
        <Popover
          placement='bottomRight'
          trigger='click'
          content={<div onClick={logout} style={{ width: 70, fontSize: 13, fontWeight: 600, cursor: 'pointer' }}>{t('logout')}</div>}
        >
          <span className='user-container' style={{ margin: transferHistoryOpen ? '0 20px 0 28px' : '0 18px 0 0' }}><UserOutlined style={{ fontSize: 16 }} /></span>
        </Popover>
        {transferHistoryOpen && <CloseOutlined style={{ fontSize: 20, color: 'rgb(0,0,0,0.45)', marginRight: 18 }} onClick={() => setTransferHistoryOpen(false)} />}
      </div>
      <Drawer
        open={transferPreviewOpen}
        width={window.innerWidth * (960 / 1920)}
        headerStyle={{ flex: 'none', height: 86 }}
        onClose={() => setTransferPreviewOpen(false)}
        maskStyle={{ backgroundColor: 'transparent' }}
        bodyStyle={{ padding: 0 }}
      >
        <div style={{ fontSize: 38, fontWeight: 'bold', paddingLeft: 48 }}>{t('transfer_preview')}</div>
        <div
          style={{ fontSize: 15, fontWeight: 700, padding: '18px 0', borderBottom: '1px solid rgb(225,225,225)', paddingLeft: 48 }}
        >
          {t('count_files', { count: fileList.length })} | {showFileSize(totalSize)} | {t('expiration_date')} {moment(expireTime + 7 * timeSize.day * 1000).format('YYYY-MM-DD HH:mm:ss')}
        </div>
        {
          fileList.map(f => (
            <div key={f.name} style={{ height: 79, display: 'flex', alignItems: 'center', borderBottom: '1px solid rgb(225,225,225)', margin: '0 48px' }}>
              <img width='24' src={fileIconMap[f.type]} alt='icon' />
              <div style={{ marginLeft: 16 }}>
                <div
                  style={{ fontSize: 14, fontWeight: 'bold', width: window.innerWidth * (960 / 1920) - 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                  title={f.name}
                >
                  {f.name}
                </div>
                <div style={{ fontSize: 12, color: 'rgb(161,161,161)' }}>{showFileSize(f.size)}</div>
              </div>
            </div>
          ))
        }
      </Drawer>
      <Drawer
        open={transferHistoryOpen}
        onClose={() => setTransferHistoryOpen(false)}
        closable={false}
        maskStyle={{ backgroundColor: 'transparent' }}
        width={window.innerWidth * (800 / 1920)}
        destroyOnClose={true}
        afterOpenChange={(open) => {
          if (open) {
            getTransferHistory()
          } else {
            setTransferHistory({ docs: [], hasNextPage: false })
            setTableParams({ page: 1, size: 15 })
          }
        }}
        className='file-page-transfer-history-drawer'
      >
        <div style={{ margin: '50px 0 24px 0', display: 'flex', alignItems: 'center' }}>
          <div style={{ fontSize: 16, fontWeight: 'bold' }}>{t('transfer_history')}</div>
          <Button type='link' onClick={handleClickClearAllFiles}>{t('clear_all_files')}</Button>
        </div>
        <div
          ref={scrollElement}
          onScrollCapture={_.debounce(onScrollCapture, 100)}
          style={{ overflow: 'auto', height: 'calc(100vh - 180px)', maxHeight: 800 }}
          className='transfer-history-table-container'
        >
          <ConfigProvider
            renderEmpty={() => (
              <div
                style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => setTransferHistoryOpen(false)}
              >
                <div style={{ position: 'relative' }}>
                  <img src={emptyHistory} width='300' height='300' alt='empty-icon' />
                  <div style={{ position: 'absolute', left: '50%', top: '62%', transform: 'translate(-50%)', fontSize: 14 }}>{t('click_to_send_file')}</div>
                </div>
              </div>
            )}
          >
            <Table
              dataSource={transferHistory.docs}
              rowKey='id'
              pagination={false}
              loading={tableLoading}
              sticky
              expandable={{
                expandIcon: ({ expanded, onExpand, record }) => (
                  expanded ?
                    <CaretDownOutlined onClick={e => onExpand(record, e)} /> :
                    <CaretRightOutlined onClick={e => onExpand(record, e)} />
                ),
                onExpand,
                expandedRowRender
              }}
            >
              <Column title={t('pin')} key='pin' dataIndex='pin' />
              <Column
                title={t('upload_time')}
                key='createdAt'
                dataIndex='createdAt'
                render={formatUploadTime}
              />
              <Column
                title={t('status')}
                key='createdAt'
                dataIndex='createdAt'
                render={(createdAt) => {
                  if (moment().unix() - createdAt > 7 * timeSize.day) {
                    return t('expired')
                  }
                  const expire = moment.unix(createdAt + 7 * timeSize.day)
                  const diffHour = expire.diff(moment(), 'hour')
                  const day = Number.parseInt(diffHour / 24)
                  const hour = diffHour - day * 24
                  return t('expired_days_hours', { day, hour })
                }}
              />
              <Column
                title={t('action')}
                key='action'
                dataIndex='action'
                align='right'
                render={(text, record) => (
                  <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    {
                    !(moment().unix() - record.createdAt > 7 * timeSize.day) &&
                      (
                        <Upload
                          fileList={[]}
                          multiple={true}
                          showUploadList={false}
                          accept={fileExtensions.join()}
                          beforeUpload={(file, fList) => beforeUpload3(file, fList, record)}
                        >
                          <Tooltip title={t('add_file')}>
                            <FileAddOutlined style={{ fontSize: 16, cursor: 'pointer', marginRight: 12 }} />
                          </Tooltip>
                        </Upload>
                      )
                    }
                    <Tooltip title={t('delete_batch')}>
                      <DeleteOutlined
                        style={{ fontSize: 16, cursor: 'pointer' }}
                        onClick={() => handleDeleteBatchById(record.id)}
                      />
                    </Tooltip>
                  </div>
                )}
              />
            </Table>
          </ConfigProvider>
        </div>
        {historyMask && <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}></div>}
      </Drawer>
      <Drawer
        open={agreementOpen}
        onClose={() => setAgreementOpen(false)}
        width={window.innerWidth * (800 / 1920)}
        maskStyle={{ backgroundColor: 'transparent' }}
      >
        <Agreement />
      </Drawer>
      <Drawer
        open={policyOpen}
        onClose={() => setPolicyOpen(false)}
        width={window.innerWidth * (800 / 1920)}
        maskStyle={{ backgroundColor: 'transparent' }}
      >
        <Policy />
      </Drawer>
    </div>
  )
}

export default File
