import moment from 'moment'
import { notification } from 'antd'
import { byteSize, fileHeaderBinary, zhNumberWeight } from './constant'
import backgroundDefaultImage from '../media/imgs/background-img.jfif'

export const isLimit100M = (size) => size <= 1024 * 1024 * 100

export const isLimitBatchCount = (length, batchCount = 10) => length <= batchCount

export const hasSameName = (fileList, flie) => !!fileList.find(f => f.name)

export const isLimit255Byte = (str) => new Blob([str]).size <= 255

const createNotification = () => {
  notification.config({
    maxCount: 3,
    placement: 'bottomLeft',
    duration: 3,
  })

  return (description, style = {}) => notification.open({
    message: '',
    description,
    style: { width: 330, minHeight: 54, backgroundColor: '#FDDA64', padding: '9px 24px', fontSize: 14, fontWeight: 'bold', ...style }
  })
}

export const openNotification = createNotification()

export const formatUploadTime = (time) => {
  const today = moment()
  const yesterday = moment().subtract(1, 'days')
  const target = moment.unix(time)
  const isToday = moment(target).isSame(today, 'day')
  const isYesterday = moment(target).isSame(yesterday, 'day')
  if (isToday) {
    return target.format('今天 HH:mm')
  }
  if (isYesterday) {
    return target.format('昨天 HH:mm')
  }
  return target.format('YYYY/MM/DD HH:mm')
}

export function isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}

export const showFileSize = (size) => {
  if(typeof size !== 'number' && !Number.isNaN(size)) {
    return ''
  }
  return size < byteSize.KB ? `${size} B` : size < byteSize.MB ? `${(size / byteSize.KB).toFixed(2)} KB` : `${(size / byteSize.MB).toFixed(2)} MB`
}

export const checkFileTypeByBinary = {
  'application/pdf': async (file) => {
    const buffer = await file.slice(0, 5).arrayBuffer()
    const unit8Array = new Uint8Array(buffer)
    return fileHeaderBinary['application/pdf'].every((item, index) => item === unit8Array[index])
  },
  'image/jpeg': async (file) => {
    const buffer = await file.slice(0, 3).arrayBuffer()
    const unit8Array = new Uint8Array(buffer)
    return fileHeaderBinary['image/jpeg'].every((item, index) => item === unit8Array[index])
  },
  'image/png': async (file) => {
    const buffer = await file.slice(0, 8).arrayBuffer()
    const unit8Array = new Uint8Array(buffer)
    return fileHeaderBinary['image/png'].every((item, index) => item === unit8Array[index])
  },
  'image/bmp': async (file) => {
    const buffer = await file.slice(0, 2).arrayBuffer()
    const unit8Array = new Uint8Array(buffer)
    return fileHeaderBinary['image/bmp'].every((item, index) => item === unit8Array[index])
  },
  'video/mp4': async (file) => {
    const buffer = await file.slice(4, 8).arrayBuffer()
    const unit8Array = new Uint8Array(buffer)
    return fileHeaderBinary['video/mp4'].every((item, index) => item === unit8Array[index])
  },
  'video/mpeg': async (file) => {
    const buffer = await file.slice(0, 4).arrayBuffer()
    const unit8Array = new Uint8Array(buffer)
    return fileHeaderBinary['video/mpeg'].every((item, index) => item === unit8Array[index])
  }
}

export const compareLetterStr = (preLetterStr, nextLetterStr, index = 0) => {
  try {
    if (preLetterStr[index] !== nextLetterStr[index]) {
      return preLetterStr[index].charCodeAt() - nextLetterStr[index].charCodeAt()
    }
    if (!preLetterStr[index + 1] || !nextLetterStr[index + 1]) {
      if (!preLetterStr[index + 1]) {
        return -1
      } else {
        return 1
      }
    }
    return compareLetterStr(preLetterStr, nextLetterStr, index + 1)
  } catch (error) {
    console.log('compareLetterStr', error)
    return 0
  }
}

export const compareZhNumberStr = (preZhNumberStr, nextZhNumberStr, index = 0) => {
  try {
    if (preZhNumberStr.length === 1 && nextZhNumberStr.length === 1) {
      return zhNumberWeight[preZhNumberStr].weight - zhNumberWeight[nextZhNumberStr].weight
    }
    if (preZhNumberStr.length === 1 || nextZhNumberStr.length === 1) {
      if (preZhNumberStr.length === 1) {
        return -1
      } else {
        return 1
      }
    } else {
      if (preZhNumberStr[index] === nextZhNumberStr[index]) {
        if (preZhNumberStr[index + 1] !== nextZhNumberStr[index + 1]) {
          return zhNumberWeight[preZhNumberStr[index + 1]].weight - zhNumberWeight[nextZhNumberStr[index + 1]].weight
        } else {
          return compareZhNumberStr(preZhNumberStr, nextZhNumberStr, index + 1)
        }
      } else {
        if (preZhNumberStr[index + 1] === nextZhNumberStr[index + 1]) {
          return zhNumberWeight[preZhNumberStr[index]].weight - zhNumberWeight[nextZhNumberStr[index]].weight
        } else {
          return zhNumberWeight[preZhNumberStr[index + 1]].weight - zhNumberWeight[nextZhNumberStr[index + 1]].weight
        }
      }
    }
  } catch (error) {
    console.log('compareZhNumberStr', error)
    return 0
  }
}

export const setAppBackground = (img) => document.body.style.backgroundImage = `url(${img})`

export const transformBlobToUrl = (data) => {
  const blob = new Blob([data])
  const reader = new FileReader()
  reader.onload = (e) => setAppBackground(e.target.result)
  reader.onerror = () => setAppBackground(backgroundDefaultImage)
  reader.readAsDataURL(blob)
}

// 字符串是否含有全角字符
export const hasFullAngle = (str) => {
  try {
    if(typeof str !== 'string' || str.length < 1) {
      return false
    }
    return str?.match(/[\uff00-\uffff]/g)?.length > 0
  } catch (error) {
    return false
  }
}

// 字符串是否含有中文字符
export const hasChineseCharacters = (str) => {
  try {
    if(typeof str !== 'string' || str.length < 1) {
      return false
    }
    return str?.match(/[\u4e00-\u9fa5]/g)?.length > 0
  } catch (error) {
    return false
  }
}

// 字符串是否含有半角字符
export const hasHalfAngle = (str) => {
  try {
    if(typeof str !== 'string' || str.length < 1) {
      return false
    }
    return str?.match(/[\u0000-\u00ff]/g)?.length > 0
  } catch (error) {
    return false
  }
}
