import './index.less'

const Input = (props) => {
  const { placeholder, onChange, onFocus, style, placeholderStyle, value, autoFocus } = props
  return (
    <div className='custom-input-container'>
      <input type='text' required='required' onInput={onChange} style={style} onFocus={onFocus} value={value} autoFocus={!!autoFocus} />
      <span className='custom-input-placeholder' style={placeholderStyle}>{placeholder}</span>
    </div>
  )
}

export default Input
