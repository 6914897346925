const Agreement = () => {
  return (
    <>
      <h1 style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>MeetingPad用户协议</h1>
      <div style={{ fontSize: 14, fontWeight: 'bold' }}>
        <div style={{ marginBottom: 14 }}>欢迎使用MeetingPad服务!</div>
        <p>提示：在使用MeetingPad服务之前，请您务必认真阅读并遵守《MeetingPad用户协议》（以下简称“本协议”）、《MeetingPad隐私政策》及《MeetingPad侵权投诉政策》（包括前述协议、政策不时的更新、修订和重述）。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款。免除或者限制责任的条款可能将以加粗字体显示，您应重点阅读。</p>
        <p>当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您以其他MeetingPad允许的方式实际使用本服务时，即表示您已充分阅读、理解并接受本协议的全部内容，本协议即产生法律约束力。您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得MeetingPad对您问询的解答等理由，主张本协议无效或要求撤销本协议。</p>
        <p>如果您因年龄、智力等因素而不具有完全民事行为能力，请在法定监护人（以下简称“监护人”）的陪同下阅读和判断是否同意本协议，或由您的监护人代理完成。</p>
      </div>
      <div>
        <h1>一、缔约主体</h1>
        <p>本协议由通过MeetingPad网站、移动客户端及桌面客户端MeetingPad应用软件以及其他方式使用MeetingPad服务的个人用户（以下简称“<strong>用户</strong>”或“<strong>您</strong>”）与上海金桥信息股份有限公司及其关联公司（以下简称 “MeetingPad公司”、“MeetingPad”或“我们”）共同缔结。</p>
      </div>
      <div>
        <h1>二、协议内容和效力</h1>
        <p>2.1 本协议内容包括本协议正文及所有我们已经发布或将来可能发布的隐私权政策、侵权投诉政策、各项政策、规则、声明、通知、警示、提示、说明（以下简称“规则”）。前述规则为本协议不可分割的组成部分，与本协议具有同等法律效力。</p>
        <p><strong>2.2 MeetingPad公司有权根据需要不时制订、修改本协议及相关规则，变更后的协议和规则将通过MeetingPad官方网站公告、邮件或短信的形式提前7日通知您并就告知完毕后即取代原协议及规则生效。如您不同意相关变更，建议将重要数据自行备份后，停止使用MeetingPad服务；如您继续使用MeetingPad服务，即表示您已接受经修订的协议和规则。</strong></p>
      </div>
      <div>
        <h1>三、服务内容</h1>
        <p>3.1 MeetingPad是一个向广大用户提供数据存储、同步、管理和分享等功能的在线服务，这些功能服务可能根据用户需求的变化，随着因服务版本不同、或服务提供方的单方判断而被优化或修改，或因定期/不定期的维护而暂缓提供。MeetingPad平台是信息存储空间平台，其本身不直接自行上传、下载、复制、分享和提供内容，对用户传输的内容亦不做任何修改或编辑。</p>
        <p><strong>3.2 MeetingPad服务包括MeetingPad桌面客户端、移动客户端、MeetingPad网页版（域名为www.meetingpad.cc）以及将MeetingPad部分功能/服务作为第三方服务集成在MeetingPad公司关联公司和/或其他第三方智能硬件、软件的服务（具体以MeetingPad公司提供的为准）。</strong>MeetingPad公司会不断丰富您使用本服务的终端、形式等，如您已注册使用一种形式的服务，则可以以同一账号使用其他服务，本协议自动适用于您对所有版本的软件和服务的使用。</p>
        <p>3.3 MeetingPad公司依据本协议、《MeetingPad隐私权政策》以及其他适用的规则，许可您对MeetingPad软件进行不可转让的、非排他性的使用。您可以制作本软件的一个副本，仅用作备份。备份副本必须包含原软件中含有的所有著作权信息，本条及本协议其他条款未明示授权的其他一切权利仍由MeetingPad公司保留，您在行使这些权利时须另外取得MeetingPad公司的书面许可。MeetingPad公司如果未行使前述任何权利，并不构成对该权利的放弃。</p>
        <p>3.4 MeetingPad公司有权自行决定对服务或服务任何部分及其相关功能、应用软件进行变更、升级、修改、转移，并在MeetingPad官网上公示通知。</p>
      </div>
      <div>
        <h1>四、注册及账号管理</h1>
        <p><strong>4.1 您确认，在您完成注册程序或以其他MeetingPad公司允许的方式实际使用服务时，您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人。若您不具备前述主体资格，请取得您的监护人的同意，如未取得，请勿使用服务，否则您及您的监护人应承担因此而导致的一切后果，且MeetingPad公司有权注销或永久冻结您的账户，并向您及您的监护人索偿。</strong></p>
        <p><strong>4.2 您知晓并确认，MeetingPad账户仅供自然人用户个人使用，您完成注册程序或以其他MeetingPad公司允许的方式实际使用服务，均不会代表除自然人以外的企业、组织机构或其他法律主体进行注册或以其他MeetingPad公司不允许的方式实际使用本服务，否则MeetingPad公司有权注销或永久冻结您的账户，因此产生的损失由您自行承担。</strong></p>
        <p>4.3 您可以使用您提供或确认的手机号或者MeetingPad公司允许的其它方式作为账号进行注册, 您注册时应提交真实、准确、完整和反映当前情况的身份及其他相关信息，您同意授权MeetingPad对您的身份信息及其他相关信息进行验证和合理使用。同时，系统将为你自动分配一个MeetingPad账号，您可以对账号昵称和头像等个人资料进行设置。<strong>您理解并同意，您承诺注册的账号名称、设置的昵称、头像等信息中不得出现违法和不良信息，不得冒充他人，不得未经许可为他人注册，不得以可能导致其他用户误认的方式注册账号或设置账号个人资料，不得采取其他使MeetingPad有理由怀疑您的资料属于程序或恶意的操作，不得使用可能侵犯他人权益的昵称或头像（包括但不限于涉嫌商标权、名誉权侵权等），否则MeetingPad公司有权不予注册或停止服务并收回MeetingPad账号，因此产生的损失由您自行承担。</strong></p>
        <p>4.4 您了解并同意，MeetingPad账号所有权归属于MeetingPad公司，注册完成后，您仅获得账号使用权。<strong>MeetingPad账号使用权仅归属于初始申请注册人，不得以任何方式转让、透露、出借、赠与或被提供予他人使用，不应用于违法违规的用途、不应用于损害他方利益、不应恶意批量注册等。否则，MeetingPad公司有权立即不经通知收回该账号，由此带来的因您使用本服务产生的全部数据、信息等被清空、丢失等的损失，以及因前述不当使用行为对MeetingPad或任何第三方造成的损失，您应自行承担。</strong></p>
        <p>4.5 在您成功注册后，MeetingPad将根据您的身份要素识别您的身份，并提供授权登录、转发、分享等服务。“身份要素”包括但不限于您的账户名称、密码、短信校验码、手机号码。<strong>您同意基于不同的终端、服务以及您的使用习惯，MeetingPad可能采取不同的验证措施识别您的身份。请您务必妥善保管您的终端及账户、密码和/或身份要素信息，并对利用该账户和密码/身份信息所进行的一切活动（包括但不限于网上点击同意、网上签署任何协议、以及上传、下载、传输、备份、同步、管理、分享任何数据以及款项支付等）负全部责任。您承诺，在密码或账户遭到未获授权的使用，或者发生其他任何安全问题时，将立即通知MeetingPad公司，且您同意并确认，除法律另有规定外，MeetingPad公司不对上述情形产生的任何直接或间接的遗失或损害承担责任。如果因为您未保管好自己的账号和密码，从而对您、MeetingPad或第三方造成的损害，您将负全部责任。除非有法律规定或司法裁定，否则，您的账户、密码不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）。</strong></p>
        <p><strong>4.6 如您注销MeetingPad或停止使用服务时，请您自行事先处理您账号内的数据并确保账号处于安全状态。</strong></p>
        <p><strong>4.7 当您符合本协议规定的相关终止条件，MeetingPad将回收存储容量，清空存储数据，由此带来的损失（包括但不限于文件丢失，数据清空等）将由您自行承担。</strong></p>
      </div>
      <div>
        <h1>五、服务使用规范</h1>
        <p>5.1 您充分了解并同意，MeetingPad仅为用户提供网络存储空间，您应对使用MeetingPad及服务发布信息内容的真实性、合法性负责。您应自行对利用MeetingPad服务从事的所有行为及结果承担责任。相应地，您应了解，使用MeetingPad服务可能发生来自他人非法或不当行为（或信息）的风险，您应自行判断及行动，并自行承担相应的风险。</p>
        <p>5.2 除非另有说明，本协议项下的服务只能用于非商业用途。您承诺不对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于包括但不限于广告及任何其它商业目的。</p>
        <p>5.3 您同意将按照MeetingPad公司公布的关于存储等各服务的使用期限、容量要求、速度要求、收费标准等相关规则使用相关服务。</p>
        <p>5.4 您承诺不会利用本服务进行任何违法或不当的活动，包括但不限于下列行为:</p>
        <p>5.4.1 制作、上载、传送、存储、下载或分享含有下列内容之一的信息：</p>
        <p>&#8226; 反对宪法所确定的基本原则的；</p>
        <p>&#8226; 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>&#8226; 损害国家荣誉和利益的；</p>
        <p>&#8226; 煽动民族仇恨、民族歧视、破坏民族团结的；</p>
        <p>&#8226; 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>&#8226; 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>&#8226; 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p>&#8226; 丑化、侮辱或者诽谤他人，侵害他人名誉权、肖像权等合法权利的；</p>
        <p>&#8226; 含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</p>
        <p>&#8226; 含有中国或其您所在国国家管辖法所适用的法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</p>
        <p>5.4.2 冒充任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关，或未经他人授权或明确同意，获取冒充对象或他人的MeetingPad服务的访问权；</p>
        <p>5.4.3 请求、收集、索取、滥用或以其他方式获取他人的MeetingPad账号、密码或其他身份验证凭据，或未经原始用户同意，将其用户信息泄露给任何非原始用户；</p>
        <p>5.4.4 伪造标题或以其他方式操控识别资料，使人误认为该内容为MeetingPad公司或其关联公司所传送；</p>
        <p>5.4.5 将依据任何法律或合约或法定关系（例如由于雇佣关系和依据保密合约所得知或揭露之内部资料、专属及机密资料）知悉但无权传送之任何内容加以上载、传播、分享或下载；</p>
        <p>5.4.6 将涉嫌侵害他人权利（包括但不限于著作权、专利权、商标权、商业秘密等知识产权）之内容，直接或者删除、隐匿、改变软件上显示或其中包含的任何专利、版权、商标或其他所有权声明，加以上载、传播、分享或下载；</p>
        <p>5.4.7 干扰或破坏MeetingPad或MeetingPad任何部分或功能的正常运行，包括但不限于避开或尝试避开MeetingPad账号注册/认证机制、MeetingPad内容保护机制；</p>
        <p>5.4.8 通过修改或伪造MeetingPad运行中的指令、数据，增加、删减、变动MeetingPad的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</p>
        <p>5.4.9 通过非MeetingPad公司开发、授权的第三方软件、插件、外挂、系统，登录或使用MeetingPad服务或为任何用户自动登录到MeetingPad账号代理身份验证凭据，或制作、发布、传播上述工具；</p>
        <p>5.4.10 自行、授权他人或利用第三方软件对他人访问、使用MeetingPad及其组件、模块、数据等进行跟踪、查看或操作；</p>
        <p>5.4.11 违反遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的行为；</p>
        <p>5.4.12 从事任何违反中国法律、法规、规章、政策及规范性文件的行为。</p>
        <p><strong>5.5 您同意并接受，尽管MeetingPad不会非法或非经授权地访问、使用、改变或披露您在MeetingPad的用户文件数据，MeetingPad有权按照法律法规和有关监管机构地规范性文件的规定对您通过MeetingPad传输、分享的文件数据，进行主动或依举报地审查、监督。若存在以不符合本协议的方式使用MeetingPad的行为，MeetingPad有权做出独立判断，且有权在无需事先通知的情况下立即终止提供部分或全部服务。对于通过MeetingPad散布和传播反动、色情或其他违反国家法律、法规的信息，MeetingPad的系统记录有可能作为违反法律法规证据。如您进行上述内容在MeetingPad服务的上载、传播而导致任何第三方提出索赔要求或衍生的任何直接与间接损害或损失，由您自行承担全部责任。如因用户利用MeetingPad服务提供的网络服务上传、发布、传送或通过其他方式传播的内容存在权利瑕疵或侵犯了第三方的合法权益（包括但不限于专利权、商标权、著作权及著作权邻接权、肖像权、隐私权、名誉权等）而导致MeetingPad面临任何投诉、举报、质询、索赔、诉讼，或者使MeetingPad因此遭受任何名誉、声誉或者财产上的损失，用户应对MeetingPad因此遭受的直接及间接经济损失负有全部的损害赔偿责任。</strong></p>
        <p>5.6 如您作为非利益相关方认为MeetingPad上存在上述一项或以上内容违法违规，您可以通过MeetingPad上的“举报”功能进行举报或通过本协议以下第十五条所列的联系方式举报。</p>
      </div>
      <div>
        <h1>六、特别授权</h1>
        <p><strong>6.1 当您向MeetingPad公司及其关联公司作出任何形式的承诺，且相关公司已确认您违反了该承诺，则MeetingPad公司有权立即按您的承诺约定的方式对您的账户采取限制措施，包括但不限于中止或终止向您提供服务，并公示相关公司确认的您的违约情况。您了解并同意，MeetingPad公司无须就相关确认与您核对事实，或另行征得您的同意，且MeetingPad公司无须就此限制措施或公示行为向您承担任何的责任。</strong></p>
        <p><strong>6.2 一旦您违反本协议，或与MeetingPad公司签订的其他协议的约定，MeetingPad公司有权以任何方式通知MeetingPad公司关联公司，要求其对您的权益采取限制措施，包括但不限于要求MeetingPad公司关联公司中止、终止对您提供部分或全部服务，且在其经营或实际控制的任何网站公示您的违约情况。</strong></p>
      </div>
      <div>
        <h1>七、个人信息和隐私保护</h1>
        <p>7.1 MeetingPad公司致力于保护您的个人信息和隐私，在您使用MeetingPad服务时，您同意MeetingPad公司按照在浏览器或客户端公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。MeetingPad希望通过隐私权政策向您清楚地介绍MeetingPad对您个人信息的处理方式，因此MeetingPad建议您完整地阅读《MeetingPad隐私政策》，以帮助您更好地保护您的隐私权。</p>
      </div>
      <div>
        <h1>八、MeetingPad知识产权</h1>
        <p>8.1 您了解及同意，除非MeetingPad公司另行声明，本协议项下包含的所有产品、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档，但您自行上传、下载、保存、制作、复制、分享的数据除外）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密等）及相关权利均归MeetingPad公司或其关联公司所有。</p>
        <p>8.2 您应保证，除非取得MeetingPad公司书面授权，对于上述权利您不得（并不得允许任何第三人）实施包括但不限于出租、出借、出售、散布、复制、修改、转载、汇编、发表、出版、还原工程、反向汇编、反向编译，或以其它方式发现原始码等的行为。</p>
        <p>8.3. MeetingPad公司及其关联公司享有对MeetingPad的Logo、“MeetingPad”等文字、图形及其组合，以及MeetingPad其他标识、MeetingPad的名称等的相关权利。未经MeetingPad公司书面授权，任何人不得以任何方式展示、使用或做其他处理（包括但不限于复制、传播、展示、镜像、上传、下载），也不得向他人表明您有权展示、使用或做其他处理。</p>
        <p>8.4 MeetingPad公司所有的产品、服务、技术与所有应用程序或其组件/功能/名称的知识产权均归属于MeetingPad公司所有或归其权利人所有。</p>
      </div>
      <div>
        <h1>九、侵权防范</h1>
        <p>9.1 您使用MeetingPad应保证您在MeetingPad上传、下载、保存、制作、复制、分享的任何数据拥有充分、完整、无瑕疵的知识产权或已获得权利人做出的包含在MeetingPad上传、下载、保存、制作、复制或分享该数据的明确授权，且未侵犯任何第三方的合法权益。如需获得该信息内容权利人或任何有权第三方的事先授权或批准的，应事先获得该等授权或批准；一旦您发布某项信息内容，将被视为您已事先获得该等授权或批准。</p>
        <p><strong>9.2 您知悉并认可，如果您传输、制作、复制、分享内容违法违规或侵犯他人权利的，MeetingPad公司有权根据相关法律法规或针对第三方发出的合格的侵权通知进行独立判断和处理，包括但不限于，按照要求采取屏蔽或断开链接、冻结、删除等措施，以及采取适当的政策，限制或禁止MeetingPad的功能或部分功能，以杜绝在相应条件下的重复侵权。</strong></p>
        <p><strong>9.3 如您收到侵权通知，应立即删除侵权内容或禁止对侵权内容的访问，并联系MeetingPad及递送通知的人员以了解详细信息。如您认为被删除或屏蔽、断开链接的内容并未侵权，您可按照本协议第十六条的联系方式向MeetingPad提交书面说明（“反通知”），要求恢复被删除的内容，或者恢复与被断开的内容的链接。</strong></p>
        <p>9.4 如您认为MeetingPad里的内容侵犯了您的其合法权益的，可以通过向MeetingPad提出书面权利通知，MeetingPad将在收到权利人合格通知后依法尽快处理。具体流程请参见《MeetingPad侵权投诉政策》。</p>
      </div>
      <div>
        <h1>十、服务中止或终止</h1>
        <p><strong>10.1 鉴于互联网服务的特殊性，您同意MeetingPad公司有权对服务内容进行变更，也可能会中断、中止或终止全部或者任一部分的服务，并在MeetingPad官网上以公示的形式通知您，MeetingPad公司无需对任何用户或任何第三方承担任何责任。</strong></p>
        <p><strong>10.2 您了解并同意，MeetingPad公司可能定期或不定期地对提供网络服务的平台设备、设施和软硬件进行维护或检修，如因此类情况而造成收费服务在合理时间内中止，MeetingPad公司无需承担责任，但应事先在MeetingPad官网以公示的形式通告您。</strong></p>
        <p><strong>10.3 MeetingPad服务可在任何时候为任何原因变更本服务或删除其部分功能。MeetingPad服务可在任何时候取消或终止对用户的服务。中止或终止服务后，MeetingPad将尽最大努力通过合理方式通知用户。当您发现无法登录或享受服务时，可以咨询我们。一旦服务取消或终止，用户使用本服务的权利立即终止。 一旦本服务取消或终止，用户在本服务中储存的任何信息可能无法恢复。</strong></p>
      </div>
      <div>
        <h1>十一、有限责任</h1>
        <p><strong>11.1 所有产品和服务将按"现状"和按"可得到"的状态提供。MeetingPad公司在此明确声明对产品和服务不作任何明示或暗示的保证，包括但不限于对产品和服务的可适用性，没有错误或疏漏，持续性，准确性，可靠性，适用于某一特定用途之类的保证，声明或承诺。</strong></p>
        <p><strong>11.2 MeetingPad公司对以下内容均不作承诺和保证：</strong></p>
        <p><strong>11.2.1 产品和服务所涉的技术和信息的有效性，准确性，正确性，可靠性，质量，稳定，完整和及时性。</strong></p>
        <p><strong>11.2.2 MeetingPad服务适合用户的使用要求。</strong></p>
        <p><strong>11.2.3 MeetingPad服务不受干扰，及时、安全、可靠或不出现错误；及用户经由MeetingPad服务取得的任何产品、服务或其他材料符合用户的期望。</strong></p>
        <p><strong>11.2.4 用户使用经由MeetingPad服务下载或取得的任何资料，其风险由用户自行承担；因该等使用导致用户电脑系统损坏或资料流失，用户应自己负完全责任。</strong></p>
        <p><strong>11.3 不论在何种情况下，MeetingPad公司均不对由于Internet连接故障，电脑，通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。</strong></p>
        <p><strong>11.4 不论是否可以预见，不论是源于何种形式的行为，MeetingPad公司不对由以下原因造成的任何特别的，直接的，间接的，惩罚性的，突发性的或附随的损害或其他任何损害（包括但不限于利润或利息的损失，营业中止，资料灭失）承担责任：</strong></p>
        <p><strong>11.4.1 使用或不能使用产品和服务；</strong></p>
        <p><strong>11.4.2 通过产品和服务购买或获取任何产品，样品，数据，信息或进行交易等，或其他可替代上述行为的行为而产生的费用；</strong></p>
        <p><strong>11.4.3 未经授权的存取或修改数据或数据的传输；</strong></p>
        <p><strong>11.4.4 第三方通过产品和服务所作的陈述或行为；</strong></p>
        <p><strong>11.4.5 其它与产品和服务相关事件，包括疏忽等，所造成的损害。</strong></p>
        <p><strong>11.5 您充分了解并同意，鉴于互联网体制及环境的特殊性，您在产品和服务中分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途；您在此已充分意识此类风险的存在，并确认此等风险应完全由您自行承担，MeetingPad公司对此不承担任何责任。</strong></p>
        <p><strong>11.6 您了解并同意，在使用产品和服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）及匿名或冒名的信息的风险，基于产品和服务使用规范所述，该等风险应由您自行承担，MeetingPad公司对此不承担任何责任。</strong></p>
        <p><strong>11.7 您了解并同意，在您使用MeetingPad的某些服务（例如首页背景）时，尽管MeetingPad会以中国大陆地区普遍可接受的思想、道德、审美等观念为标准，您可能接触到MeetingPad自动生成的令您不快或感到被侵犯的内容。您可以选择不使用该等功能，或联系我们反馈意见，但您同意不会以此追究MeetingPad公司的相关责任。</strong></p>
      </div>
      <div>
        <h1>十二、违约责任及赔偿</h1>
        <p>12.1 如果我们发现或收到他人举报您有违反本协议约定的，MeetingPad公司有权不经通知随时对相关内容进行删除、屏蔽，并采取包括但不限于限制、暂停、终止您使用MeetingPad账号及服务，限制、暂停、终止您使用部分或全部本服务，追究法律责任等措施。</p>
        <p><strong>12.2 您同意，由于您通过MeetingPad上传、下载、保存、制作、复制、分享的信息、使用本产品和服务其他功能（包括但不限于表情包功能）、违反本协议、或您侵害他人任何权利（包括但不限于知识产权、肖像权、名誉权等）因而衍生或导致任何第三人向MeetingPad公司及其关联公司提出任何索赔或请求，或MeetingPad公司及其关联公司因此而发生任何损失，您同意将足额进行赔偿（包括但不限于律师费、诉讼费及其他因此支出的合理费用），保证MeetingPad公司不受任何直接与间接损失。</strong></p>
      </div>
      <div>
        <h1>十三、有效通知</h1>
        <p>13.1 MeetingPad公司向您发出的任何通知，可采用电子邮件、页面公开区域公告、个人网络区域提示、手机短信等方式，且该等通知应自相关信息发出之日起视为已向用户送达或生效。</p>
        <p>13.2 您同意，您向MeetingPad发出的任何通知应发至MeetingPad公司对外正式公布或以第16条所述方式告知用户的联系方式或使用其他MeetingPad公司认可的其他通知方式进行送达。</p>
      </div>
      <div>
        <h1>十四、争议解决及其他</h1>
        <p><strong>14.1 本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理。如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方可提交法院诉讼解决。</strong></p>
        <p>14.2 如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部份则仍具有法律效力。</p>
        <p>14.3 MeetingPad公司于用户过失或违约时放弃本协议规定的权利的，不得视为其对用户的其他或以后同类之过失或违约行为弃权。</p>
        <p><strong>14.4 本协议一经公布即生效，您登录、使用任何MeetingPad服务即视为您已阅读并同意受本协议的约束。MeetingPad公司有权随时对协议内容进行修改，修改后的结果公布于MeetingPad网站上。如果不同意MeetingPad公司对本协议相关条款所做的修改，用户有权停止使用网络服务。如果用户继续使用网络服务，则视为用户接受MeetingPad公司对本协议相关条款所做的修改。</strong></p>
        <p>14.5 本协议应取代双方此前就本协议任何事项达成的全部口头和书面协议、安排、谅解和通信。</p>
        <p>14.6 您理解并同意，MeetingPad公司有权自主决定经营策略并根据业务调整情况将本协议项下的全部权利义务一并转移给其关联公司或任何第三方，转让将以本协议规定的方式通知，用户承诺对此不持有异议。</p>
      </div>
      <div>
        <h1>十五、联系方式</h1>
        <p>如果您有任何疑问、意见或建议，请通过以下联系方式与我们联系：</p>
        <p>电子邮件：MeetingPad@Shgbit.com</p>
      </div>
    </>
  )
}

export default Agreement
