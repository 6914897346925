import { Drawer } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import P from '../../components/policy'
import Desc from '../../components/desc'
import './index.less'

const Policy = ({ history }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className='policy-page-add-file' >
        <PlusOutlined style={{ margin: '0 21px 0 34px', fontSize: 32 }} />{t('add_file')}
      </div>
      <div className='policy-page-desc'>
        <Desc />
      </div>
      <Drawer
        open={true}
        // width={800}
        width={window.innerWidth * (800 / 1920)}
        // closable={false}
        maskStyle={{ background: 'transparent' }}
        className='policy-page-drawer'
        onClose={() => history.push('/login')}
      >
        <P />
      </Drawer>
    </div>
  )
}

export default Policy
