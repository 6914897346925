import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import AppContext from '../../AppContext'

const Desc = () => {
  const { t } = useTranslation()
  const context = useContext(AppContext)

  return (
    <>
      <span>{t('upload_rule_first')}</span>
      <span>{t('upload_rule_secend')}</span>
      <span>{t('upload_rule_third', { count: context.batchCount })}</span>
    </>
  )
}

export default Desc
