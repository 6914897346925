import React from 'react';
import './index.css';

function Progress(props) {
    return (
        <div className="uploading-chart uploading">
            <div className="process-anime">
                {/* <div className="cube cube-a" style={{ top: '0%' }}></div> */}
                <div className="cube cube-b" style={{ top: '0%' }}></div>
                <div className="fx-mask"></div>
                <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAA8CAYAAACelmGhAAAC7ElEQVRYhe2ZzUtUURiHH4cyzQzSFkbfkVmBuJHIFhFEtHERgS3DVbv6D/pY1cogwlUR9LkokChCCAwXRpBBbcq+sJiFQRKFhlR+/OLYuTXNOXecuXPvnRY98OJ43uv7zJx7zpl7jlWSiMh6oAvYA2wEVgNLgA/AI+A+cAuYCi1v5CVGi6Qbkma1MB8ldUuq8jmchgJRJ+m8pJkipPn0SaqNKt8q6YVTsjSGJC0tVd4h6VOZ4oCrpch3SZpwSpRHZ1C/0GjfbEftSidTHqNACzCTCSmzDLiTgNiwCThoXoTJzwLbnNb4OGoq+bp9NzBocgnK54A1+Z98MXAhYTG2x/fmyw8DW5xLk2FnbrcvAl7bdToNHuR+8kMpig0bcuXdTjpZGoNuXwe8KzD1kuD3ItOVstjwPRDud1LJM2G63YzyL0BdyvIR88m3V0BsGDXyVqc5HZ5n7LdMxeQNTnM6DBr58gqIXwFZI69xUsnTTwUWloArgfyzk0qWZ8DTQD6Wsrw3eGHkb510crwHLufKH6coPw1MB78EX6lZYK1zabyYe90OzAZVg9F+O2HxDHAkV5wrv+5cHi8ngWGnYs6+bCjmPVnAzWL25x1FbvhLYTh/WxwmN9Ebo/iJpEafNExeLemhU6Z0+iXV+4SF5CYayrj/PyQdl5Tx1HXCabBRY29BKWNgQFKbp1ZohCZstEq6KGnSUf1i3OZ3eP52wfBtkX2Y3WsbsAqoBb4Bb4CXZrZ6ri+Kcg4By6ZSDxPz/Jf/U3JzetwEVDuZBOX7gAFg0h5dfwXu2mkWP3YhMFOux1lC/jAl6UCUhaRQBMkTjs7FvIHmQsWiyE3BaUfl55KvSNQw9/yYPQYrhs4477u51+bsrdnJhFNvB2LZZCKcvRXbSwsSNs/DGLfnN7HJR5zWcO6FZiLK+5xWP+aBv8ebiYqkFZLGvBPrb04ltci023/A+ZiTdCbswT8OuYkmSeckZa3Q/Lxm31is0vmQ+AnQOtlY/wFl5gAAAABJRU5ErkJggg=="
                    className="fx" alt='img'/>
                <div id="value"></div>
                <svg width="190" height="170" id="svg" style={{ display: "none" }}>
                    <polyline fill="none" stroke="#FFFFFF" strokeWidth="18" points="42,102 82,135 145,66" strokeLinecap="round"
                        strokeLinejoin="round" />
                </svg>
            </div>
        </div>
    )
}

let hoc = (WrappedComponent) => {
    return class EnhancedComponet extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                num: this.props.num
            }
        }

        componentWillReceiveProps(next) {
            if (next.num !== this.props.num) {
                let num = next.num;
                let cube = document.querySelectorAll('.cube');
                let value = document.getElementById('value');
                let svg = document.getElementById('svg')
                if (num <= 100) {
                    cube.forEach(node => {
                        node.style.top = -num + '%';
                        if (num < 100) {
                            value.style.display = 'block'
                            value.innerText = num + '%'

                            svg.style.display = 'none'
                        } else {
                            value.style.display = 'none';
                            svg.style.display = 'block'
                            svg.children[0].classList = 'tick'
                        }
                    })
                } else {
                    value.style.display = 'none';
                    svg.style.display = 'block'
                    svg.children[0].classList = 'tick'
                }
                // if (num <= 100) {
                //     cube.forEach(node => {
                //         node.style.top = -num + '%';
                //         if (num < 101) {
                //             value.style.display = 'block'
                //             value.innerText = num + '%'

                //             svg.style.display = 'none'
                //         } else {
                //             value.style.display = 'none';
                //             svg.style.display = 'block'
                //             svg.children[0].classList = 'tick'
                //         }
                //     })
                // } else {
                //     value.style.display = 'none';
                //     svg.style.display = 'block'
                //     svg.children[0].classList = 'tick'
                // }

                this.setState({ num })
            }
        }

        render() {
            return <WrappedComponent
            />
        }
    }
}

export default hoc(Progress);
